import * as React from 'react';
export const SvgWorddocx = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="worddocx_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.worddocx_svg__st1{fill:#fff}.worddocx_svg__st2{fill:#999}.worddocx_svg__st4{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}'
      }
    </style>
    <path opacity={0.4} fill="#231f20" d="M3 0v16h13V4.6L11.4 0z" />
    <path className="worddocx_svg__st1" d="M4 15V1h7l4 4v10z" />
    <path
      className="worddocx_svg__st2"
      d="M5 3h7v1H5zM5 5h9v1H5zM5 7h9v1H5zM5 9h9v1H5zM5 11h9v1H5zM5 13h9v1H5z"
    />
    <path className="worddocx_svg__st1" d="M11 5V1l4 4z" />
    <path opacity={0.5} fill="#231f20" d="M11 5V1h-1v5h5V5z" />
    <g id="worddocx_svg___x31_6_8_">
      <path className="worddocx_svg__st4" d="M4 2.5v11.9l4 .6V2z" />
      <path
        d="M0 4l7-1v11l-7-1.1V4z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#2c5898"
      />
      <path
        className="worddocx_svg__st4"
        d="M2 9.1h1V11H2zM4 9.1h1V11H4zM5 6.5h1v3H5zM3 6.5h1v3H3zM1 6.5h1v3H1z"
      />
    </g>
  </svg>
);
export default SvgWorddocx;
