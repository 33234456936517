import React from 'react';
import * as hopscotch from 'hopscotch';
import 'hopscotch/dist/css/hopscotch.min.css';
import './hopscotch.override.css';
import i18n from '../../i18n';
import {
  OnboardingMessagesFlags,
  putOnboardingFlags,
} from '../../hooks/useOnboardingMessages';
import { ShareTourDefinition } from '../../services/shareTour';

interface IProps {
  tour: ShareTourDefinition;
  flags: OnboardingMessagesFlags;
  productSettingsServiceUrl: string;
  accessToken: string;
  setOnboardingTourClosed: (value: boolean) => any;
}

export const Hopscotch = (props: IProps) => {
  startTour(props);
  return <></>;
};

const startTour = (props: IProps) => {
  hopscotch.startTour(getFormattedHopscotchTour(props));
};

const getFormattedHopscotchTour = (props: IProps) => {
  const tour = props.tour;
  tour.onStart = setupTourStart;
  tour.onShow = () => {
    updateFlag(tour, props.flags);
    setupTourShow(props.flags);
  };
  tour.onClose = () => {
    putOnboardingFlags(
      props.productSettingsServiceUrl,
      props.accessToken,
      props.flags
    );
    hideOverlay();
    props.setOnboardingTourClosed(true);
  };
  tour.onEnd = () => {
    putOnboardingFlags(
      props.productSettingsServiceUrl,
      props.accessToken,
      props.flags
    );
    hopscotch.endTour(true);
    hideOverlay();
  };
  tour.onNext = () => {
    updateFlag(tour, props.flags);
  };
  return tour;
};

const updateFlag = (
  tour: ShareTourDefinition,
  flags: OnboardingMessagesFlags
) => {
  const id = hopscotch.getCurrStepNum();
  flags[tour.steps[id].id as keyof typeof flags] = true;
};

const setupTourStart = () => {
  if (hopscotch.getCurrTour()) {
    //Remove overflow from body
    document.body.style.overflowY = 'hidden';
  }
};

const setupTourShow = (flags: OnboardingMessagesFlags) => {
  addOverlay();
  const tourInfo = onboardingTourInfo();
  if (tourInfo.curTourSteps[tourInfo.step]) {
    const curTarget = tourInfo.curTourSteps[tourInfo.step].target;
    removeArrow(curTarget);
    setArrowPosition(curTarget, tourInfo.curTourSteps[tourInfo.step].placement);
  }
  manageStepCircles();
  addTourNextStepOption();
  addTourSkipOption(flags);
};

const onboardingTourInfo = () => {
  let length: number = 0;
  let steps: StepDefinition[] = [];
  let step: number = 0;
  let id: string = '';
  if (hopscotch.getCurrTour()) {
    length = hopscotch.getCurrTour().steps.length;
    steps = hopscotch.getCurrTour().steps;
    id = hopscotch.getCurrTour().id;
  }
  if (hopscotch.getCurrStepNum()) {
    step = hopscotch.getCurrStepNum();
  }
  return {
    length: length,
    step: step,
    curTourSteps: steps,
    curTourId: id,
  };
};

const getElementByClass = (target: string): HTMLElement | null => {
  const targetElements: HTMLCollectionOf<Element> = document.getElementsByClassName(
    target
  );
  return targetElements.length > 0 ? (targetElements[0] as HTMLElement) : null;
};

const removeArrow = (
  curTarget: string | HTMLElement | (string | HTMLElement)[]
) => {
  if (curTarget === 'body') {
    const hopscotchArrow: HTMLElement | null = getElementByClass(
      'hopscotch-bubble-arrow-container'
    );
    if (hopscotchArrow && hopscotchArrow.style) {
      hopscotchArrow.style.display = 'none';
    }
  }
};

const setArrowPosition = (
  currTarget: string | HTMLElement | (string | HTMLElement)[],
  tourStepPlacement: placementTypes
) => {
  const shouldUpdateArrow: boolean =
    (tourStepPlacement === 'bottom' || tourStepPlacement === 'top') &&
    currTarget !== '#input-display-container';
  if (shouldUpdateArrow) {
    let targetWidth: number = 0;
    const currentTargetElement = document.querySelector(
      currTarget.toString()
    ) as HTMLElement;
    if (currentTargetElement) {
      targetWidth = currentTargetElement.offsetWidth;
    }
    const hopscotchBubbleArrowContainer: HTMLElement | null = getElementByClass(
      'hopscotch-bubble-arrow-container'
    );
    const hopscotchArrowBorder: HTMLElement | null = getElementByClass(
      'hopscotch-bubble-arrow-border'
    );
    let arrowWidth: number = 0;
    let arrowMarginLeft: number = 1;
    let arrowMarginRight: number = 1;
    let arrowXOffset: number = 0;
    if (hopscotchBubbleArrowContainer && hopscotchBubbleArrowContainer.style) {
      arrowWidth = hopscotchBubbleArrowContainer.offsetWidth;
      if (hopscotchArrowBorder) {
        if (
          hopscotchArrowBorder.style &&
          hopscotchArrowBorder.style.marginLeft
        ) {
          arrowMarginLeft = +hopscotchArrowBorder.style.marginLeft.replace(
            /[^\d.-]/g,
            ''
          );
        }
        if (
          hopscotchArrowBorder.style &&
          hopscotchArrowBorder.style.marginRight
        ) {
          arrowMarginRight = +hopscotchArrowBorder.style.marginRight.replace(
            /[^\d.-]/g,
            ''
          );
        }
        arrowXOffset =
          targetWidth / 2 - arrowWidth / 2 + arrowMarginLeft + arrowMarginRight;
        hopscotchBubbleArrowContainer.style.left = arrowXOffset + 'px';
      }
    }
  }
};

const manageStepCircles = () => {
  const tourInfo = onboardingTourInfo();
  const hopscotchCircleContainer: HTMLCollectionOf<Element> = document.getElementsByClassName(
    'hopscotch-circle-container'
  );

  if (0 === hopscotchCircleContainer.length && tourInfo.length > 1) {
    const hopscotchAction = getElementByClass('hopscotch-actions');
    if (hopscotchAction) {
      hopscotchAction.insertAdjacentHTML(
        'beforebegin',
        "<div class='hopscotch-circle-container hopscotch-cta'></div>"
      );
    }
  }

  const hopscotchCircles: HTMLCollectionOf<Element> = document.getElementsByClassName(
    'hopscotch-circle'
  );
  if (0 === hopscotchCircles.length && tourInfo.length > 1) {
    for (let i = 0; i < tourInfo.length; i++) {
      const hopscotchCircleContainerElement = getElementByClass(
        'hopscotch-circle-container'
      );
      if (hopscotchCircleContainerElement) {
        const hopscotchCircleContainerDiv: HTMLDivElement = document.createElement(
          'div'
        );
        hopscotchCircleContainerDiv.setAttribute('class', 'hopscotch-circle');
        hopscotchCircleContainerDiv.setAttribute('value', i.toString());
        hopscotchCircleContainerElement.appendChild(
          hopscotchCircleContainerDiv
        );
        hopscotchCircleContainerDiv.onclick = onStepCircleClick;
      }
    }
  }

  if (0 < hopscotchCircles.length) {
    const currTourStep: HTMLElement = hopscotchCircles[
      tourInfo.step
    ] as HTMLElement;
    const prevTourStep: HTMLElement = hopscotchCircles[
      tourInfo.step - 1
    ] as HTMLElement;

    if (currTourStep && currTourStep.classList) {
      currTourStep.classList.add('filled');
    }

    if (prevTourStep && prevTourStep.classList) {
      prevTourStep.classList.remove('filled');
    }
  }
};

const onStepCircleClick = (e: any) => {
  e.preventDefault();
  const tourInfo = onboardingTourInfo();
  const stepNum = +e.target.getAttribute('value');
  if (stepNum !== tourInfo.step) {
    hopscotch.showStep(stepNum);
  }
};

const addTourNextStepOption = () => {
  const hopscotchNext: HTMLElement | null = getElementByClass('hopscotch-next');
  if (hopscotchNext) {
    hopscotchNext.innerText = isLastStep() ? i18n.t('Done') : i18n.t('Next');
  }
};

const isLastStep = () => {
  const tourInfo = onboardingTourInfo();
  return tourInfo.length === tourInfo.step + 1;
};

export const addOverlay = () => {
  const hopscotchOverlay: HTMLElement | null = document.getElementById(
    'hopScotch-overlay'
  );

  if (hopscotchOverlay && hopscotchOverlay.parentNode) {
    hopscotchOverlay.parentNode.removeChild(hopscotchOverlay);
  }

  const hopscotchOverlayElement: HTMLDivElement = document.createElement('div');
  hopscotchOverlayElement.setAttribute('id', 'hopScotch-overlay');
  hopscotchOverlayElement.setAttribute('class', 'hopscotch-overlay');
  hopscotchOverlayElement.setAttribute('data-testid', 'hopscotch-overlay');

  document.body.appendChild(hopscotchOverlayElement);
};

const hideOverlay = () => {
  const hopscotchOverlay: HTMLElement | null = document.getElementById(
    'hopScotch-overlay'
  );

  if (hopscotchOverlay) {
    fadeOutEffect(hopscotchOverlay);
    if (hopscotchOverlay.parentNode) {
      hopscotchOverlay.parentNode.removeChild(hopscotchOverlay);
    }
  }
};

const fadeOutEffect = (fadeTarget: HTMLElement) => {
  const fadeEffect = setInterval(function() {
    if (!fadeTarget.style.opacity) {
      fadeTarget.style.opacity = '1';
    }

    if (parseInt(fadeTarget.style.opacity, 0) > 0) {
      const newOpacity: number = parseInt(fadeTarget.style.opacity, 0) - 0.1;
      fadeTarget.style.opacity = newOpacity.toString();
    } else {
      clearInterval(fadeEffect);
    }
  }, 200);
};

const addTourSkipOption = (flags: OnboardingMessagesFlags) => {
  const hopscotchSkipElements: HTMLCollectionOf<Element> = document.getElementsByClassName(
    'hopscotch-tour-skip'
  );

  if (0 === hopscotchSkipElements.length) {
    const tourSkipElement: HTMLAnchorElement = document.createElement('a');
    tourSkipElement.setAttribute('class', 'hopscotch-tour-skip');
    tourSkipElement.textContent = i18n.t('Do Not Show Again');

    const hopscotchActionElement: HTMLElement | null = getElementByClass(
      'hopscotch-actions'
    );

    if (hopscotchActionElement) {
      hopscotchActionElement.appendChild(tourSkipElement);
    }
  }

  const hopscotchTourSkip: HTMLElement | null = getElementByClass(
    'hopscotch-tour-skip'
  );

  if (hopscotchTourSkip) {
    hopscotchTourSkip.onclick = () => {
      //Set all onboarding messages to seen state
      flags.Welcome = true;
      flags.Navigation = true;
      flags.MSOffice = true;
      flags.LocalSync = true;
      flags.GetStarted = true;
      flags.FolderAccess = true;
      flags.FileLock = true;
      flags.DragAndDrop = true;
      flags.AuditTrail = true;
      //Skip tour
      hopscotch.endTour(true);
    };
  }
};

export default Hopscotch;
