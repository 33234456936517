import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import SvgMore2 from '@bentley/bwc-react/icons/More2';
import './contextMenu.css';
import { bindActionCreators } from 'redux';
import { setSelection } from '../../actions/selectionActions';
import { MenuItemAction } from '@bentley/bwc-react/innersource/Toolbar/Toolbar';
import { Dropdown } from '@bentley/bwc-react/innersource/Dropdown/Dropdown';
import { Position, Positioner } from '@bentley/bwc-react/utils/Positioner';
import { useTranslation } from 'react-i18next';

export interface ContextMenuProps {
  menuOptions: MenuItemAction[];
  isOpen: boolean;
  instanceId: string;
  toggleContextMenu: () => void;
  setSelection: (instanceIds: string[]) => void;
  close: () => void;
}

export const ContextMenu: React.FunctionComponent<ContextMenuProps> = (
  props: any
): any => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props.isOpen]);

  const handleClickOutside = (event: any) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target) &&
      props.isOpen
    ) {
      event.preventDefault();
      event.stopPropagation();
      props.close();
    }
  };

  return (
    <div>
      <div
        ref={wrapperRef}
        className={'context-menu-icon'}
        title={t('More')}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          props.setSelection([props.instanceId]);
          props.toggleContextMenu();
        }}
        onKeyDown={() => {}}
        role={'button'}
        tabIndex={0}
      >
        <SvgMore2 />
      </div>
      {props.isOpen && (
        <Positioner
          getParentRef={() => wrapperRef}
          position={Position.BOTTOM_RIGHT}
        >
          <Dropdown
            dropdownActions={props.menuOptions}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              props.close();
            }}
            ref={dropDownRef}
          />
        </Positioner>
      )}
    </div>
  );
};

export const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ setSelection }, dispatch);
};

export default connect(
  null,
  mapDispatchToProps
)(ContextMenu);
