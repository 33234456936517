import {
  SET_AUDIT_LOADING,
  SET_AUDIT_ITEM,
  SET_AUDIT_DATA,
  SET_AUDIT_ERROR,
} from '../actions/actionConstants';

import { IAuditRecord } from './../components/auditTrail/auditRecord';

export interface AuditState {
  currentItem: any;
  loadedRecords: IAuditRecord[] | undefined;
  haveMoreData: boolean;
  isLoading: boolean;
  error: string;
}

export const initialState: AuditState = {
  currentItem: undefined,
  loadedRecords: undefined,
  haveMoreData: false,
  isLoading: false,
  error: '',
};

export const AuditReducer = (state: AuditState = initialState, action: any) => {
  switch (action.type) {
    case SET_AUDIT_ITEM:
      return {
        ...initialState,
        currentItem: action.payload,
      };

    case SET_AUDIT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        haveMoreData: false,
        error: '',
      };

    case SET_AUDIT_DATA:
      return {
        ...state,
        isLoading: false,
        loadedRecords: action.payload.loadedRecords,
        haveMoreData: action.payload.haveMoreData,
        error: '',
      };

    case SET_AUDIT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        haveMoreData: false,
      };

    default:
      return state;
  }
};
