import React from 'react';
import './styles.css';
import { toast, toastType } from '../services/toastService';
import i18n from '../i18n';

export interface DragAndDropProps {
  isEnable: boolean;
  isDragging: boolean;
  setIsDragging: (value: boolean) => void;
  handleDrop: (files: any) => void;
  children?: React.ReactNode;
}

export class DragAndDrop extends React.Component<DragAndDropProps, {}> {
  private dragCounter = 0;
  private dropRef = React.createRef();

  containFiles = (event: any): boolean => {
    if (event.dataTransfer.types) {
      for (var i = 0; i < event.dataTransfer.types.length; i++) {
        if (event.dataTransfer.types[i] == 'Files') {
          return true;
        }
      }
    }
    return false;
  };

  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.props.setIsDragging(true);
    }
    if (e.dataTransfer.types && this.containFiles(e)) {
      this.props.setIsDragging(true);
    }
  };

  handleDragOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.props.setIsDragging(false);
    }
  };

  isIE = (): boolean => {
    return (
      window.navigator.userAgent.indexOf('MSIE ') > 0 ||
      !!navigator.userAgent.match(/Trident.*rv\:11\./)
    );
  };

  handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.setIsDragging(false);
    if (!this.props.isEnable) {
      return;
    }

    if (this.isIE()) {
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        this.props.handleDrop(e.dataTransfer.files);
        this.dragCounter = 0;
      }
    } else {
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        this.props.handleDrop(e.dataTransfer.items);
        this.dragCounter = 0;
      }
    }

    if (
      e.dataTransfer.types &&
      this.containFiles(e) &&
      e.dataTransfer.files &&
      e.dataTransfer.files.length == 0
    ) {
      toast(
        toastType.error,
        i18n.t('Drag and drop for folders is not supported')
      );
      this.dragCounter = 0;
    }
  };

  componentDidMount() {
    let div = this.dropRef.current;
    // @ts-ignore
    div.addEventListener('dragenter', this.handleDragIn);
    // @ts-ignore
    div.addEventListener('dragleave', this.handleDragOut);
    // @ts-ignore
    div.addEventListener('dragover', this.handleDrag);
    // @ts-ignore
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    // @ts-ignore
    div.removeEventListener('dragenter', this.handleDragIn);
    // @ts-ignore
    div.removeEventListener('dragleave', this.handleDragOut);
    // @ts-ignore
    div.removeEventListener('dragover', this.handleDrag);
    // @ts-ignore
    div.removeEventListener('drop', this.handleDrop);
  }

  render() {
    return (
      <div
        className={
          this.props.isDragging && this.props.isEnable
            ? 'drag-container dash'
            : 'drag-container'
        }
        //@ts-ignore
        ref={this.dropRef}
      >
        {this.props.children}
      </div>
    );
  }
}
