import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Alert } from '@bentley/bwc-react/core';

export interface ConfirmationForXfdfProps {
  title: string;
  primaryButtonLabel: string;
  warning: string;
  isOpen: boolean;
  primaryButtonHandle: () => void;
  onClose: () => void;
}

export const ConfirmationForXfdfFile: React.FunctionComponent<ConfirmationForXfdfProps> = (
  props: ConfirmationForXfdfProps
): any => {
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      modalRootId="modal-root"
      dismissible={true}
      closeHandle={props.onClose}
      secondaryButtonLabel={t('Cancel')}
      secondaryButtonHandle={props.onClose}
    >
      <Alert type="warning" data-testid="get-alertFor-xfdf-files">
        {t(props.warning) as string}
      </Alert>
    </Modal>
  );
};
