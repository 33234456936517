import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  useParams,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import Layout from './layout';
import './app.css';
import { ProjectShareApp } from '../src/projectShareApp';
import { User } from 'oidc-client';
import { useAppConfiguration } from './hooks/useAppConfiguration';
import { CompleteSignIn } from './components/completeSignIn/completeSignIn';
import { Login } from './components/login/login';
import { LogOut } from './components/logout/logOut';
import { OnboardingMessages } from './components/onboardingMessages/onboardingMessages';
import { DefaultView } from './components/defaultView/defaultView';
import { getRedirectPath } from '../src/services/getLinkService';
import { CompleteSignOut } from './components/completeSignOut/completeSignOut';

const PrivateRoute = (props: any) => {
  const params = useParams<typeof props>();
  const additionalProps = {
    projectId: params.projectId
      ? params.projectId
      : params.projectId !== 'undefined'
      ? params.projectId
      : undefined,
    folderId: params.folderId ? params.folderId : undefined,
    itemId: params.itemId ? params.itemId : undefined,
  };
  const allProps = { ...props, ...additionalProps };
  const { component } = props;
  const [user, setUser] = useState<User>(props.user);
  const [redirectPath, setPath] = useState<string>('');
  const [isConfigurationLoaded, manager] = useAppConfiguration();

  useEffect(() => {
    if (isConfigurationLoaded) {
      const path = getRedirectPath(
        params.projectId,
        params.folderId,
        params.itemId
      );
      setPath(`/login/${path}`);
      manager.events.addAccessTokenExpired(() => {
        manager.signinRedirect({
          state: { from: `${window.location.origin}/${path}` },
        });
      });
      manager.events.addUserLoaded(() => {
        manager
          .getUser()
          .then((usr: any) => {
            setUser(usr ? usr : ({} as User));
          })
          .catch((err: any) => {
          });
      });
      manager.events.addSilentRenewError((e: any) => {
        console.error(`Failed to renew access token silently: ${e.message}`, [
          e.message,
        ]);
        console.log('silent renew error', e.message);
      });
    }
  }, [isConfigurationLoaded]);
  return user != null && !user.expired ? (
    <Layout
      user={user}
      connectUrl={props.connectUrl}
      projectId={params.projectId}
    >
      {React.createElement(component, { ...allProps, user })}
    </Layout>
  ) : redirectPath !== '' ? (
    <Redirect to={redirectPath} />
  ) : (
    <></>
  );
};

const App: React.FunctionComponent<any> = () => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isLogging, setIsLogging] = useState<Boolean>(true);
  const [isConfigurationLoaded, manager, configuration] = useAppConfiguration();
  useEffect(() => {
    if (isConfigurationLoaded) {
      manager.getUser().then((usr: React.SetStateAction<User | undefined>) => {
        setUser(usr);
        setIsLogging(false);
      });
    }
  }, [isConfigurationLoaded]);

  return !isLogging && isConfigurationLoaded ? (
    <Router>
      <Switch>
        <Route
          exact
          path={'/onboardingMessages'}
          render={() => (
            <PrivateRoute
              user={user}
              component={OnboardingMessages}
              path="/onboardingMessages"
              productSettingsServiceUrl={
                configuration.buddiUrls.productSettingsServiceUrl
              }
              connectUrl={configuration.buddiUrls.connectPortalUrl}
            />
          )}
        />
        <Route
          exact
          path={'/signin-oidc'}
          render={() => <CompleteSignIn setUser={setUser} user={user} />}
        />
        <Route
          exact
          path={'/login/:projectId'}
          render={() => <Login user={user} />}
        />
        <Route
          exact
          path={'/login/:projectId/:folderId'}
          render={() => <Login user={user} />}
        />
        <Route
          exact
          path={'/login/:projectId/:folderId/:itemId'}
          render={() => <Login user={user} />}
        />
        <Route exact path="/logout/:projectId" render={() => <LogOut />} />
        <Route exact path="/signout-oidc" render={() => <CompleteSignOut />} />
        <Route
          exact
          path={'/'}
          render={() => (
            <PrivateRoute
              user={user}
              component={DefaultView}
              path="/"
              urls={configuration.buddiUrls}
              connectUrl={configuration.buddiUrls.connectPortalUrl}
            />
          )}
        />
        <Route
          exact
          path={'/:projectId'}
          render={() => (
            <PrivateRoute
              user={user}
              component={ProjectShareApp}
              path="/:projectId"
              urls={configuration.buddiUrls}
              connectUrl={configuration.buddiUrls.connectPortalUrl}
              applicationInsightsKey={
                configuration.applicationInsightsinstrumentationKey
              }
              buddiRegion={configuration.buddiRegion}
            />
          )}
        />
        <Route
          exact
          path={'/:projectId/:folderId'}
          render={() => (
            <PrivateRoute
              user={user}
              component={ProjectShareApp}
              path="/:projectId/:folderId"
              urls={configuration.buddiUrls}
              connectUrl={configuration.buddiUrls.connectPortalUrl}
              applicationInsightsKey={
                configuration.applicationInsightsinstrumentationKey
              }
              buddiRegion={configuration.buddiRegion}
            />
          )}
        />
        <Route
          exact
          path={'/:projectId/:folderId/:itemId'}
          render={() => (
            <PrivateRoute
              user={user}
              component={ProjectShareApp}
              path="/:projectId/:folderId/:itemId"
              urls={configuration.buddiUrls}
              connectUrl={configuration.buddiUrls.connectPortalUrl}
              applicationInsightsKey={
                configuration.applicationInsightsinstrumentationKey
              }
              buddiRegion={configuration.buddiRegion}
            />
          )}
        />
      </Switch>
    </Router>
  ) : null;
};

export default hot(App);
