import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '@bentley/bwc-react/core/Modal/Modal';
import { createFolder } from '../../actions/createFolderActions';
import './modalInputs.css';
import { useTranslation } from 'react-i18next';
import { FolderNameInput } from '../inputs/folderNameInput';

export interface CreateFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
  createFolder: (folderName: string) => void;
}

export const CreateFolderModal: React.FunctionComponent<
  CreateFolderModalProps
> = props => {
  const [folderName, setFolderName] = useState<string>('');
  const [isInputInvalid, setIsInputInvalid] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (inputRef != null && inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [props.isOpen]);

  const close = () => {
    setFolderName('');
    props.onClose();
  };

  const create = () => {
    props.createFolder(folderName.trim());
    close();
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.charCode == 13) {
      // Enter
      if (!isInputInvalid) {
        create();
      }
    }
  };

  return (
    <Modal
      modalRootId="modal-root"
      isOpen={props.isOpen}
      title={t('Create Folder')}
      closeHandle={close}
      isPrimaryDisabled={isInputInvalid}
      primaryButtonHandle={create}
      primaryButtonLabel={t('Create')}
      secondaryButtonHandle={close}
      secondaryButtonLabel={t('Cancel')}
    >
      <FolderNameInput
        label={t('Folder name')}
        data-testid="new-folder-name-input"
        className="get-link-input"
        value={folderName}
        onKeyPress={onKeyPress}
        setFolderName={setFolderName}
        setIsInputInvalid={setIsInputInvalid}
        forwardedRef={inputRef}
      />
    </Modal>
  );
};

export const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ createFolder }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(CreateFolderModal);
