import React from 'react';
import { UserIcon } from '../userIcon/userIcon';
import TruncateText from '../truncateText/truncateText';
import './auditRecord.css';

export interface IAuditRecord {
  recordBy: string;
  recordDate: string;
  action: string;
  values: IAuditValue[];
}
export interface IAuditValue {
  property?: string;
  oldValue?: string;
  newValue?: string;
}

export type AuditRecordProps = {
  className?: string;
  auditRecord: IAuditRecord;
};

export const AuditRecord = (props: AuditRecordProps) => {
  const { className, auditRecord } = props;

  const getRecordTime = (): JSX.Element => {
    const date = new Date(auditRecord.recordDate);
    let hours = date.getHours() % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes = date.getMinutes();
    const minutesStr =
      minutes < 10 ? '0' + minutes.toString() : minutes.toString();
    return (
      <div
        data-testid="bnt-audit-record-time"
        className={'bnt-audit-record-time'}
      >
        {`- ${hours}:${minutesStr}${date.getHours() >= 12 ? ' pm' : ' am'}`}
      </div>
    );
  };

  return (
    <div
      data-testid="bnt-audit-record"
      className={'bnt-audit-record' + (className ? ' ' + className : '')}
    >
      <div
        data-testid="bnt-audit-record-user-icon"
        className={'bnt-audit-record-user-icon'}
      >
        <UserIcon fullname={auditRecord.recordBy} />
      </div>
      <div
        data-testid="bnt-audit-record-text"
        className={'bnt-audit-record-text'}
      >
        <div
          data-testid="bnt-audit-record-user"
          className={'bnt-audit-record-user'}
        >
          <TruncateText text={auditRecord.recordBy} />
        </div>
        <div
          data-testid="bnt-audit-record-action"
          className={'bnt-audit-record-action'}
        >
          <TruncateText text={auditRecord.action} />
        </div>
        {getRecordTime()}
      </div>
    </div>
  );
};

export default AuditRecord;
