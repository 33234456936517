import React from 'react';
import './formAuditTrail.css';
import { AuditDayRecords } from './auditDayRecords';
import { IAuditRecord } from './auditRecord';

export type FormAuditTrailProps = {
  auditRecords: IAuditRecord[];
} & React.HTMLAttributes<HTMLDivElement>;

export const FormAuditTrail = (props: FormAuditTrailProps) => {
  const { auditRecords: auditTrail, className, ...rest } = props;

  const getAuditRecordsByDay = (): Array<[Date, IAuditRecord[]]> => {
    const auditRecordsByDay: Array<[Date, IAuditRecord[]]> = [];
    if (!auditTrail || !auditTrail.length) {
      return auditRecordsByDay;
    }
    let lastDate: Date | undefined = undefined;
    auditTrail
      .sort((rec1, rec2) => rec2.recordDate.localeCompare(rec1.recordDate))
      .forEach(auditRecord => {
        const curDate = new Date(auditRecord.recordDate);
        const curDateWithoutTime = new Date(
          curDate.getFullYear(),
          curDate.getMonth(),
          curDate.getDate()
        );
        if (!lastDate || lastDate.valueOf() !== curDateWithoutTime.valueOf()) {
          lastDate = new Date(curDateWithoutTime);
          auditRecordsByDay.push([curDate, [auditRecord]]);
        } else {
          auditRecordsByDay[auditRecordsByDay.length - 1][1].push(auditRecord);
        }
      });

    return auditRecordsByDay;
  };

  const containerClassName =
    'bnt-audit-trail-form' + (className ? ' ' + className : '');

  return (
    <div
      data-testid="bnt-audit-trail-form"
      className={containerClassName}
      {...rest}
    >
      {getAuditRecordsByDay().map((item, index) => (
        <AuditDayRecords date={item[0]} auditDayRecords={item[1]} key={index} />
      ))}
    </div>
  );
};

export default FormAuditTrail;
