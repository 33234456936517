import {
  RECEIVE_DATA,
  SET_IS_DATA_LOADING,
  SET_DATA_ERROR,
  INVALIDATE_DATA,
  SET_IS_SEARCH,
  INVALIDATE_PAGINATION,
  SET_PAGE,
  SET_PAGES,
  SET_IS_LAST_PAGE,
  SET_EMPTY_TABLE_LABEL,
} from './actionConstants';
import { Action } from './types';
import { Item } from '../entities/entities';
import { fetchInstancePermissions } from './instancePermissionsActions';
import {
  getProjectShareUrl,
  setBreadcrumbsFromItemPath,
} from '../services/utillities';
import { store } from '../store/configureStore';
import { defaultShareOptions } from '../services/fetchHelper';
import { createRootFolder } from '../services/createFolderService';
import { toastType, toast } from '../services/toastService';
import { invalidateSelection } from './selectionActions';

export const receiveData = (
  data: Item[],
  isSearch: boolean
): Action<{ data: Item[]; isSearch: boolean }> => {
  return {
    type: RECEIVE_DATA,
    payload: { data, isSearch },
  };
};

export const setIsLoading = (isLoading: boolean): Action<boolean> => {
  return {
    type: SET_IS_DATA_LOADING,
    payload: isLoading,
  };
};

export const setError = (
  errorTitle: string,
  errorDetails: string
): Action<{ errorTitle: string; errorDetails: string }> => {
  return {
    type: SET_DATA_ERROR,
    payload: { errorTitle, errorDetails },
  };
};

export const invalidateData = () => {
  return {
    type: INVALIDATE_DATA,
  };
};

export const setIsSearch = (isSearch: boolean): Action<boolean> => {
  return {
    type: SET_IS_SEARCH,
    payload: isSearch,
  };
};

export const invalidatePagination = () => {
  return {
    type: INVALIDATE_PAGINATION,
  };
};

export const setPage = (page: number): Action<number> => {
  return {
    type: SET_PAGE,
    payload: page,
  };
};

export const setPages = (pages: number): Action<number> => {
  return {
    type: SET_PAGES,
    payload: pages,
  };
};

export const setIsLastPage = (isLast: boolean): Action<boolean> => {
  return {
    type: SET_IS_LAST_PAGE,
    payload: isLast,
  };
};

export const setEmptyTableLabel = (label: string): Action<string> => {
  return {
    type: SET_EMPTY_TABLE_LABEL,
    payload: label,
  };
};

export const mapToItem = (instances: any): Item[] => {
  return instances.map((instance: any) => {
    return {
      instanceId: instance.instanceId,
      className: instance.className,
      accessOrigin: instance.properties.AccessOrigin,
      accessUrl: instance.properties.AccessUrl,
      contentType: instance.properties.ContentType,
      createdBy: instance.properties.CreatedBy,
      createdTimeStamp: instance.properties.CreatedTimeStamp,
      deleted: instance.properties.Deleted,
      deletedTimeStamp: instance.properties.DeletedTimeStamp,
      description: instance.properties.Description,
      isAutomatedPublishingFolder:
        instance.properties.IsAutomatedPublishingFolder,
      locked: instance.properties.Locked,
      modifiedBy: instance.properties.ModifiedBy,
      modifiedTimeStamp: instance.properties.ModifiedTimeStamp,
      name: instance.properties.Name,
      parentFolderId: instance.properties.ParentFolderId,
      path: instance.properties.Path,
      rootFolder: instance.properties.RootFolder,
      size: instance.properties.Size,
      version: instance.properties.Version,
      versionComment: instance.properties.Comment,
    } as Item;
  });
};

const recycleMap = (instances: any): Item[] => {
  return instances[0].relationshipInstances.map((relInstance: any) => {
    return {
      instanceId: relInstance.relatedInstance.instanceId,
      className: relInstance.relatedInstance.className,
      accessOrigin: relInstance.relatedInstance.properties.AccessOrigin,
      contentType: relInstance.relatedInstance.properties.ContentType,
      createdBy: relInstance.relatedInstance.properties.CreatedBy,
      createdTimeStamp: relInstance.relatedInstance.properties.CreatedTimeStamp,
      deleted: relInstance.relatedInstance.properties.Deleted,
      deletedTimeStamp: relInstance.relatedInstance.properties.DeletedTimeStamp,
      description: relInstance.relatedInstance.properties.Description,
      isAutomatedPublishingFolder:
        relInstance.relatedInstance.properties.IsAutomatedPublishingFolder,
      locked: relInstance.relatedInstance.properties.Locked,
      modifiedBy: relInstance.relatedInstance.properties.ModifiedBy,
      modifiedTimeStamp:
        relInstance.relatedInstance.properties.ModifiedTimeStamp,
      name: relInstance.relatedInstance.properties.Name,
      parentFolderId: relInstance.relatedInstance.properties.ParentFolderId,
      path: relInstance.relatedInstance.properties.Path,
      rootFolder: relInstance.relatedInstance.properties.RootFolder,
      size: relInstance.relatedInstance.properties.Size,
      version: relInstance.relatedInstance.properties.Version,
    } as Item;
  });
};

export const fetchFile = (item: Item): any => {
  return fetchItem(item.instanceId, item.className);
};

export const fetchFolder = (folderId: string): any => {
  return fetchItem(folderId, 'Folder');
};

export const fetchItem = (itemId: string, className: string): any => {
  const url = `${getProjectShareUrl()}/${className}?$filter=$id in ['${itemId}']`;
  return (): Promise<Item> => {
    return fetch(url, { ...defaultShareOptions(), method: 'GET' })
      .then(response => response.json())
      .then((response: any) => {
        if (response.errorMessage) {
          console.error(
            `Failed to fetch item data: ${response.errorMessage}`,
            [response.errorMessage]
          );
          return {} as Item;
        }
        console.log('Fetched Item data successfully');
        return response.instances.length == 0
          ? ({} as Item)
          : (mapToItem(response.instances)[0] as Item);
      });
  };
};

export const getPageExpression = (prefPage?: number) => {
  const page: number = prefPage != null ? prefPage : store.getState().data.page;
  const pageSize: number = store.getState().data.pageSize;
  return `?$skip=${page * pageSize}&$top=${pageSize}`;
};

export const fetchNextPage = (
  folderId?: string,
  onlyFolders: boolean = false,
  doReceive: boolean = true,
  page?: number
) => {
  if (!store.getState().data.isLastPage) {
    store.dispatch(setPage(store.getState().data.page + 1));
    store.dispatch(setPages(store.getState().data.pages + 1));
    return store.getState().data.isSearch
      ? fetchSearchResults(store.getState().searchText)
      : fetchFolderData(folderId, onlyFolders, doReceive, page);
  } else {
    return null;
  }
};

export const fetchFolderData = (
  folderId?: string,
  onlyFolders: boolean = false,
  doReceive: boolean = true,
  page?: number,
  fetchAllRecord?: boolean
) => {
  const folder = folderId
    ? folderId
    : store.getState().navigation.currentFolderId;
  const baseUrl = getProjectShareUrl();
  const url = fetchAllRecord
    ? `${baseUrl}/${
        onlyFolders ? 'Folder' : 'Item'
      }!poly/?$filter=FolderHasContent-backward-Folder.$id+eq+'${folder}'&$orderby=ItemType,%20Name%20asc`
    : `${baseUrl}/${getFolderUrl(folder, onlyFolders, page)}`;
  return fetchData(url, mapToItem, store.getState().data.isSearch, doReceive);
};

export const getFolderUrl = (
  projectId: string,
  onlyFolders: boolean = false,
  page?: number
) => {
  return `/${onlyFolders ? 'Folder' : 'Item'}!poly/${getPageExpression(
    page
  )}&$filter=FolderHasContent-backward-Folder.$id+eq+'${projectId}'&$orderby=ItemType,%20Name%20asc`;
};

export const fetchRecycleBinData = () => {
  store.dispatch(setIsLastPage(true));
  const baseUrl = getProjectShareUrl();
  const url = `${baseUrl}/Folder?$select=RecycleBinHasContent-forward-Item!poly.* `;
  return fetchData(url, recycleMap, false, true);
};

export const fetchDataByPath = (path: string) => {
  path = encodeURIComponent(path);
  const baseUrl = getProjectShareUrl();
  const url = `${baseUrl}/Item!poly/${getPageExpression()}&$filter=Path+eq+'${path}'&$orderby=ItemType,%20Name%20asc`;
  return fetchData(url, mapToItem, false, true);
};

export const fetchSearchResults = (searchText: string) => {
  return (dispatch: any, getState: any) =>
    dispatch(fetchFolder(getState().navigation.currentFolderId)).then(
      (item: Item) => {
        const isHome =
          getState().navigation.currentFolderId ===
          getState().navigation.projectId;

        dispatch(invalidateSelection());
        setBreadcrumbsFromItemPath(isHome ? undefined : [item]);

        let path = encodeURIComponent(
          item.path + (!isHome && item.className === 'Folder' ? item.name : '')
        );
        const searchValue = encodeURIComponent(searchText.replace(/'/g, "''"));
        const baseUrl = getProjectShareUrl();
        const url = `${baseUrl}/Item!poly/${getPageExpression()}&$filter=(startswith(Path, '${path}') and (Name like '${searchValue}'))&$select=*,FolderHasContent-backward-Folder.*&$orderby=ItemType, Name asc&api.filtersettings=CaseInsensitive`;
        return dispatch(fetchData(url, mapToItem, true, true));
      }
    );
};

export const fetchData = (
  url: string,
  mapFunc: any,
  isSearch: boolean,
  doReceive: boolean
) => {
  return async (dispatch: any, getState: any) => {
    if (doReceive) {
      dispatch(setIsLoading(true));
    }

    let result = null;
    try {
      result = await fetch(url, { ...defaultShareOptions() });
    } catch (error:any) {
      // Network error
      console.error(`Failed to fetch data: ${error}`, [error]);
      dispatch(setError('Error occurred', error));
      dispatch(setIsLoading(false));
      return [];
    }

    const json = await result.json();
    if (!result.ok || json.errorMessage) {
      if (
        url.endsWith(getFolderUrl(getState().navigation.projectId)) &&
        result.status == 404
      ) {
        // Workaround for newly created project
        await createRootFolder(getState().navigation.projectId);
        dispatch(invalidateData());
      } else {
        handleFetchError(result, json, dispatch);
      }
      dispatch(setIsLoading(false));
      return [];
    }

    const items: Item[] = json.instances ? mapFunc(json.instances) : [];
    const accessOrigins: string[] = [];
    if (doReceive) {
      if (items.length < getState().data.pageSize) {
        dispatch(setIsLastPage(true));
      }
      items.forEach((item: Item) => {
        if (
          item.accessOrigin != null &&
          accessOrigins.indexOf(item.accessOrigin) === -1 &&
          getState().instancePermissions.readPermissions.indexOf(
            item.accessOrigin
          ) === -1 &&
          getState().instancePermissions.writePermissions.indexOf(
            item.accessOrigin
          ) === -1 &&
          getState().instancePermissions.deletePermissions.indexOf(
            item.accessOrigin
          ) === -1
        ) {
          accessOrigins.push(item.accessOrigin);
        }
      });
      dispatch(fetchInstancePermissions(accessOrigins));
      dispatch(receiveData(items, isSearch));
      dispatch(setIsLoading(false));
      console.log('Successfully fetched data');
    }
    return items;
  };
};

const handleFetchError = async (
  response: Response,
  json: any,
  dispatch: any
) => {
  if (json.errorId === 'NotEnoughRightsInFolder') {
    console.error(`Failed to fetch data: ${json.errorId}`, [json.errorId]);
    toast(toastType.error, 'Access to folder denied');
    return;
  }

  if (response.status >= 500 && response.status < 600) {
    console.error(`Failed to fetch data: ${json.errorMessage}`, [
      json.errorMessage,
    ]);
    dispatch(setError('Error occurred', json.errorMessage));
    return;
  }
  console.error(
    `Failed to fetch data: ${response.statusText}, ResponseUrl ${response.url}`,
    [response.statusText, response.url]
  );
  console.log(
    `An unhandled error occurred. Url: ${response.url}. Status text: ${response.statusText}.`
  );
};
