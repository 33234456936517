import {
  RECEIVE_DATA,
  SET_IS_DATA_LOADING,
  SET_DATA_ERROR,
  INVALIDATE_DATA,
  SET_IS_SEARCH,
  INVALIDATE_PAGINATION,
  SET_PAGE,
  SET_PAGES,
  SET_IS_LAST_PAGE,
  SET_EMPTY_TABLE_LABEL,
} from '../actions/actionConstants';

import { Item } from '../entities/entities';

export interface DataState {
  items: Item[];
  isLoading: boolean;
  error: { errorTitle: string; errorDetails: string } | null;
  isInitialLoad: boolean;
  isSearch: boolean;
  page: number;
  pageSize: number;
  pages: number;
  isLastPage: boolean;
  emptyTableLabel: string;
}

export const initialState: DataState = {
  items: [],
  isLoading: false,
  error: null,
  isInitialLoad: false,
  isSearch: false,
  page: 0,
  pages: 1,
  pageSize: 50,
  isLastPage: false,
  emptyTableLabel: '',
};
const updateItems = (items: Item[], state: DataState) => {
  if (state.page === 0 && state.pages === 1) {
    return items;
  } else if (
    state.items.length <= state.page * state.pageSize &&
    state.page + 1 == state.pages &&
    state.pages > 1
  ) {
    return [...state.items, ...items];
  } else {
    const newState = [...state.items];
    newState.splice(state.page * state.pageSize, state.pageSize, ...items);
    return newState;
  }
};
export const DataReducer = (state: DataState = initialState, action: any) => {
  switch (action.type) {
    case RECEIVE_DATA:
      return {
        ...state,
        items: updateItems(action.payload.data, state),
        isInitialLoad: false,
        isSearch: action.payload.isSearch,
      };
    case SET_IS_DATA_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_DATA_ERROR:
      return { ...state, error: action.payload };
    case INVALIDATE_DATA:
      return {
        ...state,
        isInitialLoad: true,
        page: initialState.page,
        pages: initialState.pages,
        pageSize: initialState.pageSize,
        isLastPage: false,
      };
    case INVALIDATE_PAGINATION:
      return {
        ...state,
        page: initialState.page,
        pages: initialState.pages,
        pageSize: initialState.pageSize,
        isLastPage: false,
      };
    case SET_PAGE:
      return { ...state, page: action.payload };
    case SET_PAGES:
      return { ...state, pages: action.payload };
    case SET_IS_LAST_PAGE:
      return { ...state, isLastPage: action.payload };
    case SET_IS_SEARCH:
      return { ...state, isSearch: action.payload, isLastPage: false };
    case SET_EMPTY_TABLE_LABEL:
      return { ...state, emptyTableLabel: action.payload };
    default:
      return state;
  }
};
