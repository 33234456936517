import { RbacProjectPermissions } from '../entities/entities';
import { SET_RBAC_PROJECT_PERMISSIONS } from '../actions/actionConstants';

export const initialState: RbacProjectPermissions = {
  rbacRead: false,
  rbacWrite: false,
  rbacDelete: false,
  rbacAllowInstanceSecurity: false,
};

export const RbacProjectPermissionsReducer = (
  state: RbacProjectPermissions = initialState,
  action: any
) => {
  switch (action.type) {
    case SET_RBAC_PROJECT_PERMISSIONS:
      return action.payload;
    default:
      return state;
  }
};
