import { createFolderRequest } from './createFolderService';
import { store } from '../store/configureStore';
import { getFileType, getFileExtension } from './utillities';
import {
  MAX_CHARACTERS_ALLOWED_IN_ITEM_NAME,
  MAX_UPLOAD_FILE_SIZE,
  itemNameRegex,
} from '../constants';
import { ErrorId, ConflictActions } from '../reducers/uploadReducer';
import {
  AnonymousCredential,
  StorageURL,
  ContainerURL,
  BlockBlobURL,
  uploadBrowserDataToBlockBlob,
  Aborter,
} from '@azure/storage-blob';
import {
  hasItemWritePermissions,
  containsLockedByOther,
} from './permissionsService';
import { toastType, toast } from './toastService';
import { Item } from '../entities/entities';
import { ConflictingItem } from '../components/modals/resolveConflictsModal';
import { enqueueUploadFiles } from '../actions/uploadActions';

export const uploadFileToBlob = async (
  accessUrl: string,
  file: File,
  onProgressChange?: (ev: any) => void
) => {
  const pipeline = StorageURL.newPipeline(new AnonymousCredential());
  const containerURL = new ContainerURL(accessUrl, pipeline);
  const blockBlobURL = BlockBlobURL.fromBlobURL(containerURL as any);
  const uploadBlockSize = 4 * 1024 * 1024; // Recommended size is 4MB from what I could find
  const uploadParallelism = 20; // Recommended parallelism factor
  const uploadBlobResponse = await uploadBrowserDataToBlockBlob(
    Aborter.none,
    file,
    blockBlobURL,
    {
      blockSize: uploadBlockSize,
      parallelism: uploadParallelism,
      progress: ev => {
        if (onProgressChange) {
          onProgressChange(ev);
        }
      },
    }
  );
  return uploadBlobResponse;
};

const validatePermissions = () => {
  if (
    hasItemWritePermissions(
      store.getState().navigation.currentFolderAccessOrigin
    )
  ) {
    return true;
  } else {
    toast(
      toastType.error,
      `You don't have permission to upload files and folders`
    );
    return false;
  }
};

export const setUploadingLists = async (
  files: File[],
  folderList: Item[],
  currentFolderId: string,
  fetchFolderData: (
    folderId: string,
    onlyFolders?: boolean,
    doReceive?: boolean,
    page?: number
  ) => Promise<Item[]>,
  setConflictingExistingFiles: React.Dispatch<React.SetStateAction<Item[]>>,
  setUploadingItems: React.Dispatch<React.SetStateAction<File[]>>,
  setUploadingFolders: React.Dispatch<React.SetStateAction<Item[]>>,
  setConflictingItems: React.Dispatch<React.SetStateAction<File[]>>
) => {
  setUploadingFolders(folderList);
  const itemList = await retrieveCurrentFolderData(
    fetchFolderData,
    currentFolderId
  );

  const conflictingFiles = files.filter((file: File) =>
    itemList.some(
      (item: Item) => file.name === item.name && !containsLockedByOther([item])
    )
  );
  setConflictingItems(conflictingFiles);

  const conflictingExistingFiles = itemList.filter(
    (item: Item) =>
      files.some((file: File) => file.name === item.name) && item.accessUrl
  );
  setConflictingExistingFiles(conflictingExistingFiles);

  const uploadItems =
    conflictingFiles.length > 0
      ? files.filter(
          (file: File) =>
            !conflictingFiles.find(
              (conflicting: File) => conflicting.name === file.name
            )
        )
      : files;
  setUploadingItems(uploadItems);

  return conflictingFiles;
};

export const enqueueTransferItems = (
  parentFolderId: string,
  toggleResolveConflictsModal: () => void,
  setConflictingExistingFiles: React.Dispatch<React.SetStateAction<Item[]>>,
  setUploadingItems: React.Dispatch<React.SetStateAction<File[]>>,
  setUploadingFolders: React.Dispatch<React.SetStateAction<Item[]>>,
  setConflictingItems: React.Dispatch<React.SetStateAction<File[]>>,
  fetchFolderData: (
    folderId: string,
    onlyFolders?: boolean,
    doReceive?: boolean,
    page?: number
  ) => Promise<Item[]>,
  conflictResolutionFlag: boolean
) => async (items: any) => {
  //DataTransferItemList | FileList
  if (validatePermissions()) {
    let files: Promise<any>[] = [];
    let folders: Promise<any>[] = [];
    for (var i = 0; i < items.length; i++) {
      let file = items[i];
      if (items[i].webkitGetAsEntry) {
        // Chrome
        var item = items[i].webkitGetAsEntry();
        item.isFile ? files.push(convertToFile(item)) : folders.push(item);
      } else {
        files.push(file);
      }
    }

    const fileList = await Promise.all(files);
    const folderList = await Promise.all(folders);
    const conflictFiles = await setUploadingLists(
      fileList,
      folderList,
      parentFolderId,
      fetchFolderData,
      setConflictingExistingFiles,
      setUploadingItems,
      setUploadingFolders,
      setConflictingItems
    );

    if (conflictResolutionFlag && conflictFiles.length > 0) {
      toggleResolveConflictsModal();
    } else if (fileList.length > 0 || folderList.length > 0) {
      folderList.forEach(folder =>
        createFolderAndQueueFiles(folder, parentFolderId)
      );
      const uploadFiles: ConflictingItem[] = fileList.map(
        (file: File) =>
          ({
            file,
            actionType: ConflictActions.Upload,
          } as ConflictingItem)
      );

      store.dispatch(enqueueUploadFiles(uploadFiles, parentFolderId));
    }
  }
};

export const createFolderAndQueueFiles = async (
  directoryEntry: any,
  folderInstanceId: string
) => {
  var folderName = directoryEntry.name;
  const response = await createFolderRequest(folderName, folderInstanceId);
  const folderEntries = (await parseDirectoryEntry(directoryEntry)) as any; // FileSystemFileEntry

  var filesPromises = [];
  var instanceId = response.changedInstance.instanceAfterChange.instanceId;

  for (var i = 0; i < folderEntries.length; i++) {
    if (folderEntries[i].isDirectory) {
      createFolderAndQueueFiles(folderEntries[i], instanceId);
    } else if (folderEntries[i].isFile) {
      filesPromises.push(convertToFile(folderEntries[i]));
    }
  }

  const files = (await Promise.all(filesPromises)) as File[];
  const uploadFiles: ConflictingItem[] = files.map(
    (file: File) =>
      ({
        file,
        actionType: ConflictActions.Upload,
      } as ConflictingItem)
  );

  store.dispatch(enqueueUploadFiles(uploadFiles, instanceId));
};

export const retrieveCurrentFolderData = async (
  fetchFolderData: any,
  currentFolderId: string
) => {
  let index: number = 0;
  let response: any = [];
  let itemList: Item[] = [];
  do {
    response = await fetchFolderData(currentFolderId, false, false, index);

    itemList = [...itemList, ...response];
    index++;
  } while (response.length === 50);

  return itemList;
};

export const getFileUploadUrl = (projectShareUrl: string, projectId: string) =>
  `${projectShareUrl}/v2.4/repositories/BentleyCONNECT.ProjectShareV2--${projectId}/ProjectShare/File`;

export const getUploadStartBody = (file: File, parentFolderId: string) => {
  return {
    instance: {
      schemaName: 'ProjectShare',
      className: 'File',
      properties: {
        FileExists: false,
        Size: file.size.toString(),
        Name: file.name,
        ContentType: getFileType(file.name),
      },
      relationshipInstances: [
        {
          schemaName: 'ProjectShare',
          className: 'FolderHasContent',
          direction: 'backward',
          relatedInstance: {
            schemaName: 'ProjectShare',
            className: 'Folder',
            instanceId: parentFolderId,
            changeState: 'existing',
          },
        },
      ],
    },
  };
};
export const getUploadFinishBody = (instanceId: string) => {
  return {
    instance: {
      instanceId: instanceId,
      schemaName: 'ProjectShare',
      className: 'File',
      properties: {
        FileExists: true,
      },
      changeState: 'modified',
    },
  };
};

export const getReplaceFinishBody = (
  instanceId: string,
  newFileSize: number
) => {
  return {
    instance: {
      instanceId: instanceId,
      schemaName: 'ProjectShare',
      className: 'File',
      properties: {
        Size: newFileSize,
      },
      changeState: 'modified',
    },
  };
};

const parseDirectoryEntry = (directoryEntry: any) => {
  const directoryReader = directoryEntry.createReader();
  let allEntries: any[] = [];
  return new Promise((resolve, reject) => {
    const getAllEntries = async () => {
      directoryReader.readEntries((entries: any) => {
        if (entries.length) {
          for (let value of entries) {
            allEntries.push(value);
          }
          getAllEntries();
        } else {
          resolve(allEntries);
        }
      });
    };
    getAllEntries();
  });
};

const convertToFile = (entry: any) => {
  return new Promise((resolve, reject) => {
    entry.file(
      (file: File) => {
        resolve(file);
      },
      (err: any) => {
        reject(err);
      }
    );
  });
};

export const validateFile = (file: File): [boolean, ErrorId] => {
  const fileType = getFileExtension(file.name).toLowerCase();
  if (invalidFileTypes.indexOf(fileType) !== -1) {
    return [false, ErrorId.InvalidType];
  } else if (file.name.length > MAX_CHARACTERS_ALLOWED_IN_ITEM_NAME) {
    return [false, ErrorId.NameTooLong];
  } else if (file.name.match(itemNameRegex)) {
    return [false, ErrorId.InvalidName];
  } else if (file.size > MAX_UPLOAD_FILE_SIZE) {
    return [false, ErrorId.SizeTooBig];
  }
  return [true, ErrorId.NoError];
};

export const invalidFileTypes: string[] = [
  'exe',
  'pif',
  'application',
  'gadget',
  'msi',
  'msp',
  'com',
  'scr',
  'hta',
  'cpl',
  'msc',
  'jar',
  'py',
  'bat',
  'cmd',
  'vb',
  'vbe',
  'js',
  'ws',
  'wsf',
  'wsc',
  'wsh',
  'scf',
  'lnk',
  'inf',
  'reg',
  'docm',
  'dotm',
  'xlam',
  'pptm',
  'potm',
  'ppsm',
  'sldm',
  'pl',
  'php',
  'rb',
  'lua',
  'applescript',
  'sh',
  'bash',
  'ksh',
  'csh',
];
