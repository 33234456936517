import React from 'react';
import './fullPageLoader.scss';
import { Spinner } from '@bentley/bwc-react/core';

interface Props {
  children: React.ReactNode;
}

export const FullPageLoader: React.FunctionComponent<Props> = ({
  children,
  ...props
}) => {
  return (
    <div style={{ height: '100%', position: 'relative' }} className="full-page-loader">
      <div className="loader">
        <Spinner indeterminate />
      </div>
      {children}
    </div>
  );
};
