import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from './resources.json';

const shareI18n = i18n.createInstance();

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

shareI18n
  .use(detector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources,
    fallbackLng: 'en',
    initImmediate: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default shareI18n;
