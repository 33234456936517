import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from '@bentley/bwc-react/core/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { enqueueUploadFiles } from '../../actions/uploadActions';
import './modalInputs.css';
import { getFileType } from '../../services/utillities';
import {
  getFormattedFileSize,
  getFormattedDate,
  getFormattedTime,
} from '../../services/dataFormatters';
import { getIconByType } from '../table/itemIcon';
import { Item } from '../../entities/entities';
import { SvgChevronRight } from '@bentley/bwc-react/icons/ChevronRight';
import { SvgChevronLeft } from '@bentley/bwc-react/icons/ChevronLeft';
import { Toolbar } from '@bentley/bwc-react/innersource/Toolbar';
import './resolveConflicts.css';
import { ConflictActions } from '../../reducers/uploadReducer';
import { createFolderAndQueueFiles } from '../../services/uploadHelper';
import TruncateText from '../truncateText/truncateText';

export interface ConflictingItem {
  file: File;
  accessUrl?: string;
  instanceId?: string;
  actionType: ConflictActions;
}

export interface ResolveConflictsModalProps {
  currentFolderId: string;
  uploadingFiles: File[];
  uploadingFolders: Item[];
  conflictingFiles: File[];
  conflictingExistingFiles: Item[];
  isOpen: boolean;
  onClose: () => void;
}

interface BindedProps {
  enqueueUploadFiles: (
    files: ConflictingItem[],
    parentInstanceId: string
  ) => void;
}

export const ResolveConflictsModal: React.FunctionComponent<ResolveConflictsModalProps &
  BindedProps> = (props: ResolveConflictsModalProps & BindedProps): any => {
  const [itemId, setItemId] = useState(0);
  const [actions, setActions] = useState<string[]>([]);
  const [sameActionId, setSameActionId] = useState<number>(-1);
  const { t } = useTranslation();

  const close = () => {
    props.onClose();
  };

  const resolveConflicts = () => {
    props.uploadingFolders.forEach(folder =>
      createFolderAndQueueFiles(folder, props.currentFolderId)
    );
    const items = getItemsWithActions();
    if (items.length > 0)
      props.enqueueUploadFiles(items, props.currentFolderId);

    close();
  };

  const getItemsWithActions = (): ConflictingItem[] => {
    let uploadingItems: ConflictingItem[] = [];
    let replacedItems: ConflictingItem[] = [];

    for (let i = 0; i < actions.length; i++) {
      const existingItem = props.conflictingExistingFiles.find(
        item => item.name === props.conflictingFiles[i].name
      );

      switch (actions[i]) {
        case 'Keep':
          break;
        case 'Replace':
          replacedItems = [
            ...replacedItems,
            {
              file: props.conflictingFiles[i],
              accessUrl: existingItem ? existingItem.accessUrl : undefined,
              instanceId: existingItem ? existingItem.instanceId : undefined,
              actionType: ConflictActions.Replace,
            } as ConflictingItem,
          ];
          break;
        case 'Upload':
          uploadingItems = [
            ...uploadingItems,
            {
              file: props.conflictingFiles[i],
              accessUrl: existingItem ? existingItem.accessUrl : undefined,
              actionType: ConflictActions.Upload,
            } as ConflictingItem,
          ];
          break;
        default:
          close();
      }
    }

    // contains locked files
    const uploadingFiles = props.uploadingFiles.map(file => ({
      file: file,
      actionType: ConflictActions.Upload,
    }));

    return [...uploadingItems, ...uploadingFiles, ...replacedItems];
  };

  useEffect(() => {
    setItemId(0);
    setActions([]);
    setSameActionId(-1);
  }, [props.conflictingFiles]);

  const fillActions = (itemAction: string) => {
    const slicedActions = actions.slice(0, itemId);
    const sameActions = new Array(props.conflictingFiles.length - itemId);
    sameActions.fill(itemAction);
    return [...slicedActions, ...sameActions];
  };

  const setSameActions = () => {
    if (sameActionId !== itemId) {
      setSameActionId(itemId);
      setActions(fillActions(actions[itemId]));
    } else {
      setSameActionId(-1);
      setActions([...actions.slice(0, itemId + 1)]);
    }
  };

  const setActionList = (itemId: number, itemAction: string) => {
    if (actions[itemId] && actions[itemId] != itemAction) {
      if (sameActionId == itemId) {
        setActions(fillActions(itemAction));
      } else {
        actions.splice(itemId, 1, itemAction);
        setActions([...actions]);
      }
    } else if (!actions[itemId]) {
      setActions([...actions.concat(itemAction)]);
    }
  };

  const showNext = () => {
    setItemId(itemId + 1);
  };

  const showPrevious = () => {
    setItemId(itemId - 1);
  };

  const getActions = () => {
    return [
      {
        title: t('Back'),
        'data-testid': 'resolve-conflicts-back',
        icon: <SvgChevronLeft />,
        disabled: itemId == 0,
        onClick: showPrevious,
      },
      {
        title: t('Next'),
        'data-testid': 'resolve-conflicts-next',
        icon: <SvgChevronRight />,
        disabled:
          itemId >= props.conflictingFiles.length - 1 ||
          actions[itemId] == undefined ||
          sameActionId === itemId,
        onClick: showNext,
      },
    ];
  };

  return (
    <Modal
      className="conflictResolution"
      modalRootId="modal-root"
      dismissible={true}
      isOpen={props.isOpen}
      title={t('Conflict resolution', {
        conflictsCount: props.conflictingFiles.length,
      })}
      closeHandle={close}
      data-testid={'resolve-conflicts-title'}
    >
      <div className="conflictResolutionContent">
        <div>{t('File already exists') as string}</div>
        <FileInfo file={props.conflictingFiles[itemId]} />
        <RadioButton
          itemId={itemId}
          actionType={ConflictActions.Keep}
          onClickHandle={setActionList}
          info={t('Keep')}
          checked={actions[itemId] === ConflictActions.Keep}
          dataTestId={'resolve-conflicts-keep'}
        />
        <div id="smallerText">{t('Skip Uploading') as string}</div>
        <RadioButton
          itemId={itemId}
          actionType={ConflictActions.Replace}
          onClickHandle={setActionList}
          info={t('Replace')}
          checked={actions[itemId] === ConflictActions.Replace}
          dataTestId={'resolve-conflicts-replace'}
        />
        <div id="smallerText" />
        <RadioButton
          itemId={itemId}
          actionType={ConflictActions.Upload}
          onClickHandle={setActionList}
          info={t('Upload And Rename')}
          checked={actions[itemId] === ConflictActions.Upload}
          dataTestId={'resolve-conflicts-upload-and-rename'}
        />
        <div id="smallerText" />
        {props.conflictingFiles.length > 1 &&
          props.conflictingFiles.length - itemId - 1 != 0 && (
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  onChange={setSameActions}
                  value="sameAction"
                  checked={sameActionId == itemId}
                  disabled={actions[itemId] == undefined}
                  data-testid={'resolve-conflicts-same-action'}
                />
                {t('Do this for the next', {
                  conflictsLeft: props.conflictingFiles.length - itemId - 1,
                }) as string}
              </label>
            </div>
          )}
        <hr id="line" />
        <div className="bwc-modal-footer">
          <div className="left">
            <Toolbar id="Toolbar" toolbarActions={getActions()} />
            {props.conflictingFiles.length > 1 && (
              <div id="showingItems">
                {t('Showing', {
                  currentItem: itemId + 1,
                  itemCount: props.conflictingFiles.length,
                }) as string}
              </div>
            )}
          </div>
          <div className="right">
            <button
              className="bwc-buttons-blue bwc-modal-footer-button"
              type="button"
              data-testid="resolve-conflicts-primary-button"
              onClick={
                itemId === props.conflictingFiles.length - 1 ||
                sameActionId === itemId
                  ? resolveConflicts
                  : showNext
              }
              disabled={!actions[itemId]}
            >
              {itemId === props.conflictingFiles.length - 1 ||
              sameActionId === itemId
                ? t('Finish') as string
                : t('Next') as string}
            </button>
            <button
              className="bwc-buttons-hollow bwc-modal-footer-button"
              type="button"
              data-testid="resolve-conflicts-secondary-button"
              onClick={close}
            >
              {t('Cancel') as string}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface RadioButtonProps {
  actionType: string;
  itemId: number;
  onClickHandle: (itemId: number, itemAction: string) => void;
  checked: boolean;
  info: string;
  dataTestId: string;
}

const RadioButton = (props: RadioButtonProps) => {
  return (
    <div className="radio">
      <label>
        <input
          type="radio"
          name="action"
          value={props.actionType}
          onClick={() => {
            props.onClickHandle(props.itemId, props.actionType);
          }}
          checked={props.checked}
          onChange={() => {}}
          data-testid={props.dataTestId}
        />
        {props.info}
      </label>
    </div>
  );
};

interface FileInfoProps {
  file: any;
}
const FileInfo = (props: FileInfoProps) => {
  return (
    <>
      <div id="coloredContainer">
        <div className="bwc-icons-medium" id="conflictingItemIcon">
          {getIconByType(getFileType(props.file.name))}
        </div>
        <div id="conflictContainer">
          <div id="conflictItemName">
            <TruncateText text={props.file.name} />
          </div>
          <div id="conflictItemData">
            <div id="conflictItemDataEntry">
              {getFormattedFileSize(props.file.size.toString())}
            </div>
            <div id="conflictItemDataEntry">
              {getFormattedDate(
                new Date(
                  props.file.lastModified
                    ? props.file.lastModified
                    : props.file.lastModifiedDate
                )
              )}
            </div>
            <div id="conflictItemDataEntry">
              {getFormattedTime(
                new Date(
                  props.file.lastModified
                    ? props.file.lastModified
                    : props.file.lastModifiedDate
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const mapDispatchToProps = (dispatch: any): BindedProps =>
  bindActionCreators({ enqueueUploadFiles }, dispatch);

export default connect(null, mapDispatchToProps)(ResolveConflictsModal);
