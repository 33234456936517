import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from '@bentley/bwc-react/core/Modal/Modal';
import { Item } from '../../entities/entities';
import { Table } from '@bentley/bwc-react/innersource/Grids/ReactTable/Table';
import { useTranslation } from 'react-i18next';
import { UserRolePermissions } from '../../entities/types';
import {
  fetchUserRolePermissionsData,
  setFolderAccess,
} from '../../actions/setFolderAccessActions';
import { READ_WRITE_DELETE, READ, READ_WRITE } from '../../constants';
import { Alert } from '@bentley/bwc-react/core';
export interface SetFolderAccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  setFolderAccess: (item: Item, rolePermissions: any) => any;
  fetchUserRolePermissionsData: (item: Item) => any;
  selection: Item[];
  emptyTableLabel: string;
}
export const SetFolderAccessModal: React.FunctionComponent<SetFolderAccessModalProps> = (
  props: SetFolderAccessModalProps
): any => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [shouldShowWarning, setShouldShowWarning] = useState(false);
  const [folderName, setFolderName] = useState('');

  useEffect(() => {
    if (props.selection.length === 1) {
      setData([]);
      setIsDataLoading(true);
      setIsDataChanged(false);
      setFolderName(props.selection[0].name);
      props
        .fetchUserRolePermissionsData(props.selection[0])
        .then((response: any) => {
          setData(response);
          setIsDataLoading(false);
        });
    }
  }, [props.isOpen]);

  const checkDataChanged = () => {
    setIsDataChanged(
      !data.every(
        (row: any) =>
          row.isGuestChecked === row.isGuest &&
          row.isContributorChecked === row.isContributor &&
          row.isAdministratorChecked === row.isAdministrator
      )
    );
  };
  const close = () => {
    setShouldShowWarning(false);
    props.onClose();
  };
  const shouldSetFolderAccess = () => {
    const areRolesAdded = data.some(
      (row: any) => row.isAdministrator || row.isContributor || row.isGuest
    );
    areRolesAdded ? setShouldShowWarning(true) : setFolderAccess();
  };
  const setFolderAccess = () => {
    let rolesToSave: UserRolePermissions[] = [];
    data.forEach((row: any) => {
      const initialState =
        row.isGuestChecked === row.isGuest &&
        row.isContributorChecked === row.isContributor &&
        row.isAdministratorChecked === row.isAdministrator;

      if (initialState) {
        return;
      }

      if (row.isAdministrator) {
        rolesToSave.push({
          permissionTypeId: READ_WRITE_DELETE,
          instanceId: row.instanceId,
          secure: true,
        });
      } else if (row.isContributor) {
        rolesToSave.push({
          permissionTypeId: READ_WRITE,
          instanceId: row.instanceId,
          secure: true,
        });
      } else if (row.isGuest) {
        rolesToSave.push({
          permissionTypeId: READ,
          instanceId: row.instanceId,
          secure: true,
        });
      } else {
        rolesToSave.push({
          permissionTypeId: READ,
          instanceId: row.instanceId,
          secure: false,
        });
      }
    });

    props.setFolderAccess(props.selection[0], rolesToSave);
    setShouldShowWarning(false);
    props.onClose();
  };

  return !shouldShowWarning ? (
    <Modal
      modalRootId="modal-root"
      dismissible={true}
      isOpen={props.isOpen}
      title={t('Set Folder Access For') + ': ' + folderName}
      closeHandle={close}
      primaryButtonLabel={t('OK')}
      primaryButtonHandle={shouldSetFolderAccess}
      isPrimaryDisabled={!isDataChanged}
      secondaryButtonLabel={t('Cancel')}
      secondaryButtonHandle={close}
      className={'set-folder-access-modal'}
    >
      <Table
        className="set-folder-access-data"
        data={data ? data : []}
        resizable={true}
        loading={isDataLoading}
        minRows={isDataLoading ? 5 : data.length}
        pageSize={isDataLoading ? 5 : data.length}
        sortable={false}
        noDataText={!isDataLoading ? props.emptyTableLabel : ''}
        columns={[
          {
            Header: t<string>('Role'),
            headerStyle: { textAlign: 'left', paddingLeft: '16px' },
            accessor: 'name',
            minWidth: 200,
            resizable: false,
            Cell: ({ original }) => (
              <span title={original.name}>{original.name}</span>
            ),
          },
          {
            Header: t<string>('Read'),
            accessor: 'isGuest',
            style: { padding: 0 },
            resizable: false,
            Cell: ({ original }: any) => (
              <input
                type="checkbox"
                defaultChecked={original.isGuest}
                data-testid="toggle"
                style={{ margin: 'auto', display: 'inherit' }}
                onChange={() => {
                  original.isGuest = !original.isGuest;
                  checkDataChanged();
                }}
              />
            ),
          },
          {
            Header: t<string>('Read/Write'),
            accessor: 'isContributor',
            style: { padding: 0 },
            resizable: false,
            Cell: ({ original }: any) => (
              <input
                type="checkbox"
                defaultChecked={original.isContributor}
                data-testid="toggle"
                style={{ margin: 'auto', display: 'inherit' }}
                onChange={() => {
                  original.isContributor = !original.isContributor;
                  checkDataChanged();
                }}
              />
            ),
          },
          {
            Header: t<string>('Read/Write/Delete'),
            accessor: 'isAdministrator',
            minWidth: 150,
            style: { padding: 0 },
            resizable: false,
            Cell: ({ original }: any) => (
              <input
                type="checkbox"
                defaultChecked={original.isAdministrator}
                data-testid="toggle"
                style={{ margin: 'auto', display: 'inherit' }}
                onChange={() => {
                  original.isAdministrator = !original.isAdministrator;
                  checkDataChanged();
                }}
              />
            ),
          },
        ]}
      />
    </Modal>
  ) : (
    <Modal
      modalRootId="modal-root"
      dismissible={true}
      isOpen={props.isOpen}
      title={t('Set Folder Access For') + ': ' + folderName}
      closeHandle={close}
      primaryButtonHandle={setFolderAccess}
      primaryButtonLabel={t('OK')}
      secondaryButtonHandle={close}
      secondaryButtonLabel={t('Cancel')}
    >
      <Alert type="warning" data-testid="get-alert-onSetFolderAccess">
        {t('Set Folder Access Confirmation') as string}
      </Alert>
    </Modal>
  );
};

export const mapStateToProps = (store: any) => {
  return {
    selection: store.selection.selectedItems,
  };
};

export const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { fetchUserRolePermissionsData, setFolderAccess },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetFolderAccessModal);
