import React, { useState, useRef } from 'react';

import './truncateText.css';

export interface TruncateTextProps {
  text: string;
  className?: string;
}

const TruncateText = (props: TruncateTextProps) => {
  const { text, className } = props;

  const divRef: any = useRef();
  const [showTitle, setShowTitle] = useState(false);

  const isTruncated = () => {
    const div = divRef.current;
    return div.scrollWidth > div.offsetWidth;
  };

  const onMouseIn = (event: any) => {
    const _showTitle = isTruncated();
    setShowTitle(_showTitle);
  };

  return (
    <div
      ref={divRef}
      data-testid={'bnt-truncate-text' + (className ? ' ' + className : '')}
      className={'bnt-truncate-text' + (className ? ' ' + className : '')}
      onMouseEnter={onMouseIn}
      title={showTitle ? text : ''}
    >
      <span>{text}</span>
    </div>
  );
};

export default TruncateText;
