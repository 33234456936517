import React from 'react';
import { connect } from 'react-redux';
import { Item } from '../../entities/entities';
import Modal from '@bentley/bwc-react/core/Modal/Modal';
import { permanentlyDeleteItems } from '../../actions/deleteItemsActions';
import { invalidateSelection } from '../../actions/selectionActions';
import { bindActionCreators } from 'redux';
import { Alert } from '@bentley/bwc-react/core';
import { useTranslation } from 'react-i18next';

export interface PermanentlyDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  permanentlyDeleteItems: (items: Item[]) => void;
  invalidateSelection: () => void;
  selection: Item[];
}

export const PermanentlyDeleteModal: React.FunctionComponent<
  PermanentlyDeleteModalProps
> = (props): any => {
  const { t } = useTranslation();

  const close = () => {
    props.onClose();
  };
  const deleteHandle = () => {
    props.permanentlyDeleteItems(props.selection);
    props.invalidateSelection();
    props.onClose();
  };
  return (
    <Modal
      modalRootId="modal-root"
      dismissible={true}
      isOpen={props.isOpen}
      title={t('Delete item')}
      closeHandle={close}
      primaryButtonHandle={deleteHandle}
      primaryButtonLabel={t('Delete')}
      secondaryButtonHandle={close}
      secondaryButtonLabel={t('Cancel')}
    >
      <Alert type="warning" data-testid="get-alert-onDelete">
        {props.selection.length == 1
          ? t('Delete item confirmation') as string
          : t('Delete items confirmation') as string}
      </Alert>
    </Modal>
  );
};
export const mapStateToProps = (store: any) => {
  return {
    selection: store.selection.selectedItems,
  };
};

export const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ permanentlyDeleteItems, invalidateSelection }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermanentlyDeleteModal);
