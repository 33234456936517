import { useState } from 'react';
export const useToggle = (
  initialState: boolean
): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState<boolean>(initialState);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const close = () => {
    setIsOpen(false);
  };
  return [isOpen, toggle, close];
};
