import { createFolderRequest } from '../services/createFolderService';
import { invalidateData } from './dataActions';
import { toast, toastType } from '../services/toastService';

export const createFolder = (folderName: string) => {
  return (dispatch: any, getState: any) => {
    return createFolderRequest(
      folderName,
      getState().navigation.currentFolderId
    )
      .then(response => {
        if (response.errorMessage) {
          console.error(`Failed to create folder: ${response.errorMessage}`, [
            response.errorMessage,
          ]);
          toast(toastType.error, 'Create Folder failure');
        } else {
          dispatch(invalidateData());
          if (document.getElementsByClassName('rt-tbody')[0]) {
            document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
          }
          console.log('Created folder successfully');
          toast(toastType.success, 'Create Folder success');
        }
      })
      .catch(e => {
        console.error(`Failed to create folder: ${e}`, [e]);
        toast(toastType.error, 'Create Folder failure');
      });
  };
};
