import React from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { store } from './store/configureStore';
import { createModalRoot } from './services/createModalRoot';
import { featureTracker } from './services/featureTrackingService';
import { User } from 'oidc-client';
import ConnectedProjectShareApp from './connectedProjectShareApp';
import { ShareAppProps } from '../index';

export const ProjectShareApp: React.FunctionComponent<ShareAppProps> = (
  props: ShareAppProps
) => {
  createModalRoot();
  featureTracker.initiliseTracker(
    props.user as User,
    props.urls.featureTrackingUrl,
    props.applicationInsightsKey,
    props.projectId as string
  );
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ConnectedProjectShareApp {...props} />
      </Provider>
    </I18nextProvider>
  );
};
