import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store/configureStore';
import { UploadingItem, Status } from '../reducers/uploadReducer';
import { onStartFileUpload, onUploadFinish } from '../actions/uploadActions';
import sumBy from 'lodash/sumBy';
import { bindActionCreators } from 'redux';
import {
  MAX_SIMULTANEOUS_UPLOAD_FILES_SIZE,
  MAX_SIMULTANEOUS_UPLOAD_ITEMS_COUNT,
} from '../constants';

export interface UploadQueueObserverProps {
  uploadingItems: UploadingItem[];
  onStartFileUpload: typeof onStartFileUpload;
  onUploadFinish: typeof onUploadFinish;
  isUploading: boolean;
}

export const UploadQueueObserver: React.FunctionComponent<UploadQueueObserverProps> = (
  props: UploadQueueObserverProps
) => {
  const { uploadingItems } = props;
  const waitingItems = uploadingItems.filter(x => x.status === Status.Waiting);
  const uploadInProgressItems = uploadingItems.filter(
    x => x.status === Status.Uploading
  );

  // Start item upload
  useEffect(() => {
    if (!waitingItems.length) {
      return;
    }

    const currentUploadingFilesSize = sumBy(
      uploadInProgressItems,
      x => x.file.size
    );

    const cannotAddMoreFiles =
      currentUploadingFilesSize > MAX_SIMULTANEOUS_UPLOAD_FILES_SIZE ||
      uploadInProgressItems.length >= MAX_SIMULTANEOUS_UPLOAD_ITEMS_COUNT;
    if (cannotAddMoreFiles) {
      return;
    }

    let bytesLeftAvailable =
      MAX_SIMULTANEOUS_UPLOAD_FILES_SIZE - currentUploadingFilesSize;
    const itemsToStartUploading = [];
    for (const uploadItem of waitingItems) {
      if (uploadItem.file.size > bytesLeftAvailable) {
        break;
      }

      itemsToStartUploading.push(uploadItem);
      bytesLeftAvailable -= uploadItem.file.size;

      const newItemCount = itemsToStartUploading.length;
      if (
        newItemCount + uploadInProgressItems.length >=
        MAX_SIMULTANEOUS_UPLOAD_ITEMS_COUNT
      ) {
        break;
      }
    }

    const shouldAddOne =
      !itemsToStartUploading.length && !uploadInProgressItems.length;
    if (shouldAddOne) {
      itemsToStartUploading.push(waitingItems[0]);
    }

    for (const itemToUpload of itemsToStartUploading) {
      props.onStartFileUpload(itemToUpload.id);
    }
  });

  // Finish upload
  useEffect(() => {
    const allItemsFinished =
      uploadingItems.length &&
      !waitingItems.length &&
      !uploadInProgressItems.length;

    const isEmptyFolder =
      props.isUploading &&
      uploadingItems.length == 0 &&
      waitingItems.length == 0 &&
      uploadInProgressItems.length == 0;

    if (!isEmptyFolder && !allItemsFinished) {
      return;
    }

    props.onUploadFinish();
  });

  // Renderless
  return null;
};

export const mapStateToProps = (store: AppState) => ({
  uploadingItems: store.upload.uploadingItems,
  isUploading: store.upload.isLoading,
});
export const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      startFileUpload: onStartFileUpload,
      onUploadFinish,
      onStartFileUpload,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadQueueObserver);
