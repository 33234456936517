import { User } from '../entities/entities';
import { Action } from './types';
import { SET_USER, SET_ISADMIN } from './actionConstants';

export const setUser = (user: User): Action<User> => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const setIsAdminInOrg = (isAdmin: boolean): Action<boolean> => {
  return {
    type: SET_ISADMIN,
    payload: isAdmin,
  };
};
