import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { DataReducer } from '../reducers/dataReducer';
import { NavigationReducer } from '../reducers/navigationReducer';
import { UserReducer } from '../reducers/userReducer';
import { SelectionReducer } from '../reducers/selectionReducer';
import { UploadReducer } from '../reducers/uploadReducer';
import { SyncFoldersReducer } from '../reducers/synchronizeFoldersReducer';
import { UrlReducer } from '../reducers/urlReducer';
import { RbacProjectPermissionsReducer } from '../reducers/projectPermissionsReducer';
import { InstancePermissionReducer } from '../reducers/instancePermissionsReducer';
import { BreadcrumbsReducer } from '../reducers/breadcrumbsReducer';
import { FeaturesReducer } from '../reducers/featuresReducer';
import { RoleReducer } from '../reducers/roleReducer';
import { SearchReducer } from '../reducers/searchReducer';
import { AuditReducer } from '../reducers/auditReducer';

export const rootReducer = combineReducers({
  navigation: NavigationReducer,
  data: DataReducer,
  user: UserReducer,
  selection: SelectionReducer,
  upload: UploadReducer,
  syncedFolders: SyncFoldersReducer,
  urls: UrlReducer,
  projectPermissions: RbacProjectPermissionsReducer,
  instancePermissions: InstancePermissionReducer,
  breadcrumbs: BreadcrumbsReducer,
  features: FeaturesReducer,
  roles: RoleReducer,
  searchText: SearchReducer,
  audit: AuditReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const getEnhacers = () => {
  const enhancers = [];
  if (
    typeof window !== 'undefined' &&
    window.location.origin == 'http://localhost:5001' &&
    // @ts-ignore
    window.devToolsExtension
  ) {
    // @ts-ignore
    enhancers.push(window.devToolsExtension());
  }
  return enhancers;
};

export const store = createStore(
  rootReducer,
  {},
  compose(
    applyMiddleware(thunk),
    ...getEnhacers()
  )
);
