import { useState } from 'react';
import { BaseInstance } from '../entities/entities';

export const useSelection = <T extends BaseInstance>(
  currentData: T[],
  onSelectChange?: (items: T[]) => void
): [
  T[],
  string[],
  (ids: string[]) => void,
  (id: string) => boolean,
  () => void
] => {
  const [selection, setItemSelection] = useState<T[]>([]);

  const isSelected = (id: string): boolean =>
    selection.some((obj: T) => obj.instanceId === id);

  const invalidateSelection = (): void => {
    setItemSelection([] as T[]);
    if (onSelectChange) {
      onSelectChange([] as T[]);
    }
  };
  const setSelection = (ids: string[]) => {
    const items: T[] = currentData.filter(
      item => ids.indexOf(item.instanceId) !== -1
    );

    setItemSelection(items);
    if (onSelectChange) {
      onSelectChange(items);
    }
  };
  return [
    selection,
    selection.map(selected => selected.instanceId),
    setSelection,
    isSelected,
    invalidateSelection,
  ];
};
