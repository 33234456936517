import i18n from '../i18n';

// Id is needed to filter out unnecessary steps and is used in AppSettings
interface ShareStepDefinition extends StepDefinition {
  id: string;
}

// To add id from ShareStepDefinition, TourDefinition is redefined
export interface ShareTourDefinition extends HopscotchConfiguration {
  id: string;
  steps: ShareStepDefinition[];
}

export const ShareTour = {
  id: 'ShareTour',
  showCloseButton: true,
  showNextButton: true,
  skipIfNoElement: true,
  i18n: {
    nextBtn: 'Next',
    prevBtn: 'Prev',
    doneBtn: 'Done',
    skipBtn: 'Skip',
    closeTooltip: 'Close',
  },
  steps: [
    {
      id: 'Welcome',
      title: i18n.t('Onboarding Welcome Title'),
      content: i18n.t('Onboarding Welcome Content'),
      target: 'body',
      xOffset: 'center',
      yOffset: 'center',
      placement: 'top',
    },
    {
      id: 'GetStarted',
      title: i18n.t('Onboarding Get Started Title'),
      content: i18n.t('Onboarding Get Started Content'),
      target: '#NewButton',
      placement: 'bottom',
    },
    {
      id: 'Navigation',
      title: i18n.t('Onboarding Navigation Title'),
      content: i18n.t('Onboarding Navigation Content'),
      target: '#TableBreadCrumbs',
      placement: 'bottom',
      yOffset: '-16px',
    },
    {
      id: 'DragAndDrop',
      title: i18n.t('Onboarding Drag and Drop File(s) Upload Title'),
      content: i18n.t('Onboarding Drag and Drop File(s) Upload Content'),
      target: 'body',
      xOffset: 'center',
      yOffset: 'center',
      placement: 'bottom',
    },
    {
      id: 'MSOffice',
      title: i18n.t('Onboarding Microsoft Office Integration Title'),
      content: i18n.t('Onboarding Microsoft Office Integration Content'),
      target: 'body',
      xOffset: 'center',
      yOffset: 'center',
      placement: 'bottom',
    },
    {
      id: 'FileLock',
      title: i18n.t('Onboarding File Locking Title'),
      content: i18n.t('Onboarding File Locking Content'),
      target: '#Toolbar',
      placement: 'right',
    },
    {
      id: 'LocalSync',
      title: i18n.t('Onboarding Local Synchronization Title'),
      content: i18n.t('Onboarding Local Synchronization Content'),
      target: '#Toolbar',
      placement: 'right',
    },
    {
      id: 'FolderAccess',
      title: i18n.t('Onboarding Set Folder Access Title'),
      content: i18n.t('Onboarding Set Folder Access Content'),
      target: '#Toolbar',
      placement: 'right',
    },
    {
      id: 'AuditTrail',
      title: i18n.t('Onboarding Audit Trail Title'),
      content: i18n.t('Onboarding Audit Trail Content'),
      target: '#InformationPanel',
      placement: 'left',
    },
  ],
} as ShareTourDefinition;
