import { store } from '../store/configureStore';

const defaultHeaders = () => {
  return {
    Authorization: 'Bearer ' + store.getState().user.accessToken,
    'Content-Type': 'application/json',
  };
};

export const defaultOptions = () => {
  return {
    headers: {
      ...defaultHeaders(),
    },
  };
};

export const defaultShareOptions = () => {
  return {
    headers: {
      ...defaultHeaders(),
      'ProjectShare-Sender-GPRID': store.getState().navigation.GPRId,
    },
  };
};
