import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SelectTable } from '@bentley/bwc-react/innersource/Grids/ReactTable/SelectTable';
import { Header } from '@bentley/bwc-react/innersource/Grids/ReactTable/Header/Header';
import SvgDownload from '@bentley/bwc-react/icons/Download';
import SvgArrowUp from '@bentley/bwc-react/icons/ArrowUp';
import Modal from '@bentley/bwc-react/core/Modal/Modal';
import { invalidateSelection } from '../../actions/selectionActions';
import { invalidateData } from '../../actions/dataActions';
import { useTranslation } from 'react-i18next';
import { Item } from '../../entities/entities';
import { fetchFile } from '../../actions/dataActions';
import { downloadFiles } from '../../actions/downloadActions';
import {
  allowDownloadVersion,
  allowPromoteVersion,
} from '../../services/permissionsService';
import { fetchVersions, promoteVersion } from '../../actions/versionActions';
import {
  getFormattedFileSize,
  getFormattedDateAndTime,
} from '../../services/dataFormatters';
import { useSelection } from '../../hooks/useSelection';
import { Toolbar } from '@bentley/bwc-react/innersource/Toolbar';

export interface ManageVersionsModalProps {
  isOpen: boolean;
  selection: Item[];
  onClose: () => void;
  fetchVersions: (item: Item) => any;
  promoteVersion: (item: Item, versionId: string) => any;
  fetchFile: (item: Item) => any;
  invalidateData: any;
  invalidateSelection: any;
  emptyTableLabel: string;
}

export const ManageVersionsModal: React.FunctionComponent<ManageVersionsModalProps> = (
  props: ManageVersionsModalProps
) => {
  const [data, setData] = useState<Item[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isPromoted, setIsPromoted] = useState(false);
  const { t } = useTranslation();

  const [
    selection,
    selectedIds,
    setSelectedIds,
    isSelected,
    invalidateLocalSelection,
  ] = useSelection<Item>(data ? data : []);

  const fetchData = () => {
    props.fetchVersions(props.selection[0]).then((versionsData: Item[]) => {
      props.fetchFile(props.selection[0]).then((currentVersion: Item) => {
        currentVersion.version = t('Current');
        setData([currentVersion, ...versionsData]);
        setIsDataLoading(false);
      });
    });
  };

  useEffect(() => {
    if (props.selection.length > 0 && props.isOpen) {
      fetchData();
      setIsPromoted(false);
    }
  }, [props.isOpen]);

  const close = () => {
    setData([] as Item[]);
    setIsDataLoading(true);
    props.onClose();
    if (isPromoted) {
      props.invalidateSelection();
      props.invalidateData();
    }
  };

  const getSpanWithTitle = (content: string) => {
    return <span title={content}>{content}</span>;
  };

  const handlePromoteVersion = () => {
    if (isSelected(selection[0].instanceId)) {
      setIsDataLoading(true);
      props
        .promoteVersion(props.selection[0], selection[0].instanceId)
        .then(() => {
          invalidateLocalSelection();
          fetchData();
          setIsPromoted(true);
        });
    }
  };

  const getExtraRowProps = (state: any, rowInfo: any, instance: any) => {
    if (!rowInfo) {
      return {};
    }
    const rowDataInstance = rowInfo.row as Item;
    if (rowDataInstance.version == t('Current'))
      return {
        style: {
          fontWeight: 'bold',
        },
      };
    return {};
  };

  return (
    <Modal
      modalRootId={'modal-root'}
      dismissible={true}
      isOpen={props.isOpen}
      title={t('Manage Versions')}
      closeHandle={close}
      secondaryButtonHandle={close}
      secondaryButtonLabel={t('Close')}
    >
      <div className={'manageVersionsToolbar'} style={{ marginBottom: 10 }}>
        <Toolbar
          toolbarActions={
            [
              {
                title: t('Download Version'),
                icon: <SvgDownload />,
                disabled: !allowDownloadVersion(selection),
                onClick: () => downloadFiles(selection),
                'data-testid': 'download-version',
              },
              {
                title: t('Promote Version'),
                icon: <SvgArrowUp />,
                disabled: !allowPromoteVersion(
                  props.selection[0]?.instanceId,
                  selection
                ),
                onClick: handlePromoteVersion,
                'data-testid': 'promote-version',
              },
            ] as any[]
          }
        />
      </div>
      <SelectTable
        data={data ? data : []}
        columns={[
          {
            Header: <Header title={t('Version')} />,
            accessor: 'version',
            minWidth: 100,
            Cell: ({ original }: { original: Item }) =>
              getSpanWithTitle(original.version),
          },
          {
            Header: <Header title={t('Name')} />,
            accessor: 'name',
            minWidth: 150,
            Cell: ({ original }: { original: Item }) =>
              getSpanWithTitle(original.name),
          },
          {
            Header: <Header title={t('Size')} />,
            accessor: 'size',
            minWidth: 100,
            maxWidth: 130,
            Cell: ({ original }: { original: Item }) =>
              getFormattedFileSize(original.size),
          },
          {
            Header: <Header title={t('Last Modified')} />,
            accessor: 'modifiedTimeStamp',
            minWidth: 170,
            Cell: ({ original }: { original: Item }) =>
              getFormattedDateAndTime(new Date(original.modifiedTimeStamp)),
          },
          {
            Header: <Header title={t('Comments')} />,
            accessor: 'versionComment',
            minWidth: 150,
            Cell: ({ original }: { original: Item }) =>
              getSpanWithTitle(original.versionComment),
          },
        ]}
        selected={selectedIds}
        setSelected={setSelectedIds}
        resizable={true}
        loading={isDataLoading}
        pageSize={data ? data.length : 5}
        minRows={5}
        showPagination={false}
        sortable={false}
        keyField="instanceId"
        noDataText={!isDataLoading ? props.emptyTableLabel : ''}
        getTrGroupProps={getExtraRowProps}
      />
    </Modal>
  );
};

export const mapStateToProps = (store: any) => {
  return {
    selection: store.selection.selectedItems,
    emptyTableLabel: store.data.emptyTableLabel,
  };
};

export const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      fetchVersions,
      fetchFile,
      promoteVersion,
      invalidateData,
      invalidateSelection,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageVersionsModal);
