import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getProjectShareBaseUrl } from '../services/utillities';
import { defaultShareOptions } from '../services/fetchHelper';
import { invalidateData, fetchRecycleBinData } from './dataActions';
import { Item } from '../entities/entities';
import { AppState } from '../store/configureStore';
import { toast, toastType } from '../services/toastService';
import i18n from '../i18n';

export const deleteItems = (
  items: Item[],
  isRecycleBin: boolean
): ThunkAction<Promise<void>, AppState, {}, AnyAction> => {
  const url = `${getProjectShareBaseUrl()}/$changeset`;
  const body = JSON.stringify(data(items, 'modified', true));
  console.log(`Started soft delete`);
  return (dispatch: any): Promise<void> => {
    return fetch(url, { ...defaultShareOptions(), method: 'POST', body })
      .then(response => response.json())
      .then(response => {
        if (response.errorMessage) {
          toast(toastType.error, 'Delete Item Failure');
          console.error(`Failed to soft delete: ${response.errorMessage}`, [
            response.errorMessage,
          ]);
        } else {
          isRecycleBin
            ? dispatch(fetchRecycleBinData())
            : dispatch(invalidateData());
          if (document.getElementsByClassName('rt-tbody')[0]) {
            document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
          }
          const options = {
            link: {
              url: () => dispatch(restoreItems(items, isRecycleBin)),
              title: i18n.t('Delete undo'),
              'data-testid': 'UndoDelete',
            },
          };
          items.length == 1
            ? toast(toastType.success, 'Delete Item', options)
            : toast(toastType.success, 'Delete Items', options);
          console.log('Successfully soft deleted');
        }
      });
  };
};

export const permanentlyDeleteItems = (
  items: Item[]
): ThunkAction<Promise<void>, AppState, {}, AnyAction> => {
  const url = `${getProjectShareBaseUrl()}/$changeset`;
  const body = JSON.stringify(data(items, 'deleted', true));
  console.log(`Started permanently delete: ${url}, ${body}`, [url, body]);
  return (dispatch: any) => {
    return fetch(url, { ...defaultShareOptions(), method: 'POST', body })
      .then(response => response.json())
      .then(response => {
        if (response.errorMessage) {
          toast(toastType.error, 'Permanently Delete Item Failure');
          console.error(
            `Failed to permanently delete: ${response.errorMessage}`,
            [response.errorMessage]
          );
        } else {
          dispatch(fetchRecycleBinData());
          items.length == 1
            ? toast(toastType.success, 'Permanently Delete Item')
            : toast(toastType.success, 'Permanently Delete Items');
          console.log('Successfully permanently deleted');
        }
      });
  };
};

export const restoreItems = (
  items: Item[],
  isRecycleBin: boolean
): ThunkAction<Promise<void>, AppState, {}, AnyAction> => {
  return (dispatch: any, getState: any) => {
    return fetch(getProjectShareBaseUrl() + '/$changeset', {
      ...defaultShareOptions(),
      method: 'POST',
      body: JSON.stringify(data(items, 'modified', false)),
    })
      .then(response => response.json())
      .then(response => {
        if (response.errorMessage) {
          toast(toastType.error, 'Restore Item Failure');
        } else {
          isRecycleBin
            ? dispatch(fetchRecycleBinData())
            : dispatch(invalidateData());
          if (document.getElementsByClassName('rt-tbody')[0]) {
            document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
          }
          const options = {
            link: {
              url: () => dispatch(deleteItems(items, isRecycleBin)),
              title: i18n.t('Restore undo'),
              'data-testid': 'UndoRestore',
            },
          };
          items.length == 1
            ? toast(toastType.success, 'Restore Item', options)
            : toast(toastType.success, 'Restore Items', options);
        }
      });
  };
};

const data = (items: Item[], state: string, isDeleted: boolean) => {
  const instances = items.map(item => {
    return {
      instanceId: item.instanceId,
      schemaName: 'ProjectShare',
      className: item.className,
      changeState: state,
    };
  });

  if (state === 'deleted') {
    return { instances: instances };
  }
  return {
    instances: instances,
    requestOptions: {
      CustomOptions: {
        IsDeleted: isDeleted,
      },
    },
  };
};
