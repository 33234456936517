import { ITableBreadCrumbs } from '@bentley/bwc-react/core/TableComponents/TableBreadCrumbs/TableBreadCrumbs';
import { useState } from 'react';
import { IBreadcrumb } from '.';
import { jumpBreadcrumb } from './useBreadcrumbApi';
/**
 * @breadcrumbs ITableBreadCrumbs prop used by TableBreadCrumbs
 * @currentBreadcrumb current active breadcrumb that holds projectwise data
 * @addBreadcrumb add new breadcrumb to the breadcrumb list
 * @jumpTo go back to any of the previous breacrumb or empty to go back the first one
 */
export interface IBreadcrumbData {
  addBreadcrumb: (breadcrumb: IBreadcrumb) => void;
  breadcrumbs: IBreadcrumb[];
  currentBreadcrumb: IBreadcrumb;
  jumpTo: (instanceId: string) => () => void;
  tableBreadcrumbs: ITableBreadCrumbs;
}

function mapToTableBreadcrumb(breadcrumbs: IBreadcrumb[]) {
  return <ITableBreadCrumbs>{
    values: breadcrumbs.map(bc => bc.instanceId),
    labels: breadcrumbs.map(bc => bc.name),
    depth: breadcrumbs.length - 1,
  };
}

/**
 * useBreadcrumb is used to internal breadcrumb list
 * it also maps the data to ITableBreadCrumb that is used by TableBreadCrumbs component
 * @param initialBreadcrumbs initial breadcrumb list to begin with
 */
export function useBreadcrumb(
  initialBreadcrumbs: IBreadcrumb[]
): IBreadcrumbData {
  const [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);

  const jumpTo = (instanceId: string) => () => {
    setBreadcrumbs(prevState => jumpBreadcrumb(prevState, instanceId));
  };

  const addBreadcrumb = (breadcrumb: IBreadcrumb) => {
    setBreadcrumbs(prevState => [...prevState, breadcrumb]);
  };

  return {
    addBreadcrumb: addBreadcrumb,
    breadcrumbs: breadcrumbs,
    currentBreadcrumb: breadcrumbs[breadcrumbs.length - 1],
    jumpTo: jumpTo,
    tableBreadcrumbs: mapToTableBreadcrumb(breadcrumbs),
  };
}
