import styled from '@emotion/styled';
import React from 'react';
import { User } from 'oidc-client';
import CONNECTHeader from './components/headerFooter/CONNECTHeader';
import CONNECTFooter from './components/headerFooter/CONNECTFooter';
import { SecondaryHeader } from './components/secondaryHeader/secondaryHeader';

const StyledLayout = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const ConnectMain = styled.div`
  padding: 0 20px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
`;

const Layout: React.FunctionComponent<{
  children: JSX.Element | JSX.Element[];
  user: User | null;
  connectUrl: string;
  projectId: string;
}> = ({ children, user, connectUrl, projectId }) => {
  return (
    <StyledLayout>
      <CONNECTHeader
        userEmail={user!.profile.email!}
        connectUrl={connectUrl}
        projectId={projectId}
      />
      <SecondaryHeader />
      <ConnectMain id="connect-main">
        <div id="modal-root" />
        {children}
      </ConnectMain>
      <CONNECTFooter />
    </StyledLayout>
  );
};

export default Layout;
