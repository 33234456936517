import { SET_SEARCH_TEXT } from '../actions/actionConstants';

export const SearchReducer = (state: string = '', action: any): any => {
  switch (action.type) {
    case SET_SEARCH_TEXT:
      return action.payload;
    default:
      return state;
  }
};
