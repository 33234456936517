import { SET_FEATURES } from '../actions/actionConstants';

export interface FeaturesState {
  auditTrail: boolean;
  fileVersioning: boolean;
  folderAccess: boolean;
  imodelHubPublishing: boolean;
  pdfMarkup: boolean;
  webView: boolean;
  conflictResolution: boolean;
  isWopiEnabled: boolean;
  isWopiDeprecationInformationEnabled: boolean;
}

export const initialState: FeaturesState = {
  auditTrail: false,
  fileVersioning: false,
  folderAccess: false,
  imodelHubPublishing: false,
  pdfMarkup: false,
  webView: false,
  conflictResolution: false,
  isWopiEnabled: false,
  isWopiDeprecationInformationEnabled: false,
};

export const FeaturesReducer = (
  state: FeaturesState = initialState,
  action: any
) => {
  switch (action.type) {
    case SET_FEATURES:
      return action.payload;
    default:
      return state;
  }
};
