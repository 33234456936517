// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-page-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }
  .full-page-loader .loader {
    margin: 16px; }
`, "",{"version":3,"sources":["webpack://./srcDemoApp/components/fullPageLoader/fullPageLoader.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EAMvB,WAAW;EACX,YAAY,EAAA;EAXd;IAOI,YAAY,EAAA","sourcesContent":[".full-page-loader {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  .loader {\n    margin: 16px;\n  }\n\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
