import React, { useEffect } from 'react';
import { FullPageLoader } from '../../components/fullPageLoader/fullPageLoader';
import { useAppConfiguration } from '../../hooks/useAppConfiguration';

export const CompleteSignIn: React.FunctionComponent<any> = props => {
  const [isConfigurationLoaded, manager] = useAppConfiguration();
  useEffect(() => {
    if (isConfigurationLoaded) {
      manager.signinRedirectCallback().then((user: any) => {
        props.setUser(user);
        window.location.href = user.state.from;
      });
    }
  }, [isConfigurationLoaded]);
  return <FullPageLoader>Completing sign in...</FullPageLoader>;
};
