export const getFormattedDate = (dateTime: Date): string =>
  dateTime.toLocaleDateString([], {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

export const getFormattedTime = (dateTime: Date): string =>
  dateTime.toLocaleTimeString([], {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  });

export const getFormattedDateAndTime = (dateTime: Date): string =>
  dateTime.toLocaleTimeString([], {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  });

export const getFormattedFileSize = (size: string): string => {
  if (size) {
    var bytes = parseInt(size);
    if (bytes == 0) return '0 B';
    var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (sizes.length <= i) i = sizes.length - 1;
    if (0 == i) i = 0;
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(1)) + ' ' + sizes[i];
  }
  return ' ';
};
