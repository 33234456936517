import React from 'react';
import { NoDataGrid } from './noDataGrid';
import { EmptyRecycleBin } from '../icons/EmptyRecycleBin';
import { useTranslation } from 'react-i18next';

export interface EmptyRecycleBinGridProps {
  isLoading: boolean;
}

export const EmptyRecycleBinGrid: React.FunctionComponent<EmptyRecycleBinGridProps> = (
  props: EmptyRecycleBinGridProps
): any => {
  const { t } = useTranslation();
  return (
    <NoDataGrid
      isLoading={props.isLoading}
      image={<EmptyRecycleBin />}
      noFilesText={t('There Are No Files In Your Recycle Bin')}
      showBrowse={false}
    />
  );
};
