import * as React from 'react';
export const SvgLocked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" {...props}>
    <defs>
      <style>
        {'.Locked_svg__cls-1{fill:#3287c8}.Locked_svg__cls-2{fill:#fff}'}
      </style>
    </defs>
    <g id="Locked_svg__Layer_2" data-name="Layer 2">
      <g id="Locked_svg__destroyed">
        <path
          className="Locked_svg__cls-1"
          d="M8 1a7 7 0 1 0 7 7 7 7 0 0 0-7-7zm2.5 9.72a.52.52 0 0 1-.52.52H6a.52.52 0 0 1-.52-.52V7.9A.52.52 0 0 1 6 7.38V6.7a2 2 0 1 1 4 0v.68a.52.52 0 0 1 .52.52z"
        />
        <path
          className="Locked_svg__cls-1"
          d="M8 5.6a1.1 1.1 0 0 0-1.1 1.1v.68h2.2V6.7A1.1 1.1 0 0 0 8 5.6z"
        />
        <path
          className="Locked_svg__cls-2"
          d="M8 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm0 15a7 7 0 1 1 7-7 7 7 0 0 1-7 7z"
        />
        <path
          className="Locked_svg__cls-2"
          d="M10 7.38V6.7a2 2 0 1 0-3.91 0v.68H6a.52.52 0 0 0-.52.52v2.85a.52.52 0 0 0 .52.52h4a.52.52 0 0 0 .52-.52V7.89a.52.52 0 0 0-.52-.51zm-.87 0H6.92V6.7a1.1 1.1 0 1 1 2.2 0z"
        />
      </g>
    </g>
  </svg>
);
export default SvgLocked;
