import React, { useEffect } from 'react';
import { FullPageLoader } from '../../components/fullPageLoader/fullPageLoader';
import { useAppConfiguration } from '../../hooks/useAppConfiguration';

export const CompleteSignOut: React.FunctionComponent<any> = props => {
  const [isConfigurationLoaded, manager] = useAppConfiguration();
  useEffect(() => {
    if (isConfigurationLoaded) {
      manager.signoutRedirectCallback().then((res: any) => {
        manager.signinRedirect({ ...res });
      });
    }
  }, [isConfigurationLoaded]);
  return <FullPageLoader>Completing sign out...</FullPageLoader>;
};
