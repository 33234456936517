// buddi regions
export const devRegion = '103';
export const qaRegion = '102';

//  regex
export const itemNameRegex: RegExp = /[<>|:"?/\\*]+/i;
export const itemLeadSpaceRegex: RegExp = /^\s+/i;
export const itemTrailSpaceRegex: RegExp = /\s+$/i;

// share permissions
export const READ = 'Read';
export const READ_WRITE = 'Read/Write';
export const READ_WRITE_DELETE = 'Read/Write/Delete';

// file restrictations
export const MAX_UPLOAD_FILE_SIZE = 10737418240; // 10GB
export const MAX_ZIP_DOWNLOAD_FILE_SIZE = 1073741824; // 1GB
export const MAX_CHARACTERS_ALLOWED_IN_ITEM_NAME = 255;
export const MAX_SIMULTANEOUS_UPLOAD_ITEMS_COUNT = 5;
export const MAX_SIMULTANEOUS_UPLOAD_FILES_SIZE = 50000000; // ~50MB

//Default pagination page size
export const DEFAULT_PAGINATION_PAGE_SIZE = 50;
