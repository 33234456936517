import './InformationPanel.css';

import React, { useState, useEffect } from 'react';
import SvgDocument from '@bentley/bwc-react/icons/Document';
import { Tabs, Tab } from '../tabs/tabs';
import { connect } from 'react-redux';
import { InfoPanel } from '@bentley/bwc-react/innersource/InfoPanel';
import { PanelTitleWithIcon } from '@bentley/bwc-react/innersource/InfoPanel/PanelTitleWithIcon/PanelTitleWithIcon';

import { Item } from '../../entities/entities';
import { useTranslation } from 'react-i18next';
import { getIconByType, getItemIconProperties } from '../table/itemIcon';
import { setItem } from '../../actions/auditActions';
import { Subheading } from '@bentley/bwc-react/core/Typography/Subheading';
import { Body } from '@bentley/bwc-react/core/Typography/Body';

import DetailInfo from './detailInfo';

import AuditInfo from './auditInfo';
import { bindActionCreators } from 'redux';
import { FeaturesState } from '../../reducers/featuresReducer';
import { featureTracker } from '../../services/featureTrackingService';
import { SvgTechnicalPreviewBadge } from '@bentley/bwc-react/icons/TechnicalPreviewBadge';
import { getFileType } from '../../services/utillities';

export interface InformationPanelProps {
  isOpen: boolean;
  toggleManageVersionsModal: () => void;
  onClose: () => void;
  selectedItems: Item[];
  setItem: (itemId: string) => void;
  features: FeaturesState;
  isRecycleBin: boolean;
}

const InformationPanel: React.FunctionComponent<InformationPanelProps> = (
  props: any
): any => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    props.setItem(
      props.selectedItems.length == 1 ? props.selectedItems[0] : undefined
    );
  }, [props.selectedItems]);

  const getPanelHeader = () => {
    return props.selectedItems.length === 0 ? (
      ''
    ) : props.selectedItems.length !== 1 ? (
      t('Items selected', { itemsSelected: props.selectedItems.length })
    ) : (
      <PanelTitleWithIcon
        text={props.selectedItems[0].name}
        icon={getIconByType(
          getItemIconProperties(props.selectedItems[0]).iconType != 'Folder'
            ? getFileType(props.selectedItems[0].name)
            : 'Folder'
        )}
      />
    );
  };

  const getDetailInfo = () => {
    return (
      <DetailInfo
        toggleManageVersionsModal={props.toggleManageVersionsModal}
        selectedItems={props.selectedItems}
        isVersioningEnabled={
          props.features.fileVersioning && !props.isRecycleBin
        }
        key={'selectedItems-details'}
      />
    );
  };

  const getAuditInfo = () => {
    return (
      <div className="audit-scrolling">
        <AuditInfo isOpen={activeTab == 1 && props.isOpen} key={'audit-info'} />
      </div>
    );
  };

  const getPanelBody = () => {
    if (props.selectedItems.length == 0) {
      return (
        <div className={'info-panel-empty-state'}>
          <SvgDocument className={'document'} />
          <Subheading variant={true} placeholder={undefined}>{t('No Item Selected') as string}</Subheading>
          <Body placeholder={undefined}>{t('Select An Item To See It’s Details') as string}</Body>
        </div>
      );
    }

    if (props.selectedItems.length > 1) {
      return (
        <div className={'info-panel-empty-state'}>
          <SvgDocument className={'document'} />
        </div>
      );
    }

    if (!props.features.auditTrail) {
      return getDetailInfo();
    }

    return (
      <Tabs
        style={{ height: '100%' }}
        tabsStyle="light"
        activeIndex={activeTab}
        onChangeTab={index => {
          if (index == 1) {
            featureTracker.trackfeature('Portal_AuditTrail');
          }
          setActiveTab(index);
        }}
      >
        <Tab title={t('Details')}>{getDetailInfo()}</Tab>
        <Tab
          title={t('Audit trail')}
          badge={
            <SvgTechnicalPreviewBadge
              style={{
                height: '16px',
                marginTop: '-12px',
                marginLeft: '-16px',
              }}
            />
          }
        >
          {getAuditInfo()}
        </Tab>
      </Tabs>
    );
  };

  return (
    <InfoPanel
      className="informational-panel"
      style={{ height: '100%', backgroundColor: 'white' }}
      header={getPanelHeader()}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      {getPanelBody()}
    </InfoPanel>
  );
};

const mapStateToProps = (store: any) => {
  return {
    selectedItems: store.selection.selectedItems,
    features: store.features,
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ setItem }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InformationPanel);
