import React from 'react';
import './onboardingMessages.css';
import { ShareTour } from '../../../src/services/shareTour';
import {
  useOnboardingMessages,
  putOnboardingFlags,
} from '../../../src/hooks/useOnboardingMessages';
import { User } from 'oidc-client';

type OnboardingMessagesProps = {
  user: User;
  productSettingsServiceUrl: string;
};

export const OnboardingMessages: React.FunctionComponent<OnboardingMessagesProps> = props => {
  const [flags, , setFlags] = useOnboardingMessages(
    props.productSettingsServiceUrl,
    props.user.access_token
  );

  const toggleFlag = (id: keyof typeof flags) => {
    //Reverse the value of flag
    flags[id] = !flags[id];
    setFlags({ ...flags });
    //Use Put method to update data in Project Settings
    putOnboardingFlags(
      props.productSettingsServiceUrl,
      props.user.access_token,
      flags
    );
  };

  const whiteListRoles = ['SITE_ADMINISTRATOR'];
  const whiteListOrgs = [
    'Bentley Systems Inc',
    'Trimble Navigation Ltd',
    'BentleyCONNECTQA',
  ];

  if (
    whiteListOrgs.includes(props.user.profile.org_name) &&
    whiteListRoles.some(role => props.user.profile.role.includes(role))
  ) {
    return (
      <>
        <div id="OnboardingMessagesHeader">
          ProjectShare Onboarding Messages
        </div>
        <div id="OnboardingGrid">
          {ShareTour.steps.map(step => {
            const stepIndex = step.id as keyof typeof flags;
            return (
              <React.Fragment key={step.id}>
                <div id="OnboardingGridItem">{stepIndex}</div>
                <div id="OnboardingGridItem">
                  <input
                    id="OnboardingGridCheckBox"
                    type="checkbox"
                    checked={flags[stepIndex]}
                    onChange={() => toggleFlag(stepIndex)}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </>
    );
  } else return <div id="OnboardingMessagesHeader">Forbidden</div>;
};
