import { Item } from '../../entities/entities';
export const handleCheckBoxSelection = (
  selectedItems: Item[],
  selectedRowInstanceId: any,
  setLastSelectedWithoutShift: any
) => {
  const selectedItemsIds = selectedItems.map(item => item.instanceId);
  setLastSelectedWithoutShift(selectedRowInstanceId);

  if (selectedItemsIds.indexOf(selectedRowInstanceId) === -1) {
    return selectSingleRow(selectedItems, selectedRowInstanceId);
  } else {
    const itemsToBeSelected = selectedItemsIds.filter(
      id => id != selectedRowInstanceId
    );
    return itemsToBeSelected;
  }
};
export const getRowClickSelection = (
  sortedItems: Item[],
  selectedItems: Item[],
  selectedRowInstanceId: any,
  shiftKey: boolean,
  lastSelectedWithoutShift: string,
  setLastSelectedWithoutShift: any
) => {
  const selectedItemsIds = selectedItems.map(item => item.instanceId);
  let itemsToBeSelected: string[] = [];
  if (!shiftKey) {
    setLastSelectedWithoutShift(selectedRowInstanceId);
  }
  if (selectedItemsIds.indexOf(selectedRowInstanceId) === -1) {
    const itemsToBeSelected = handleRowClick(
      sortedItems,
      selectedItems,
      selectedRowInstanceId,
      shiftKey,
      lastSelectedWithoutShift,
      setLastSelectedWithoutShift
    );
    return itemsToBeSelected;
  } else {
    itemsToBeSelected = selectedItemsIds.filter(
      id => id != selectedRowInstanceId
    );
    return itemsToBeSelected;
  }
};
const handleRowClick = (
  items: Item[],
  selectedItems: Item[],
  selectedRowInstanceId: any,
  shiftKey: boolean,
  lastSelectedWithoutShift: string,
  setLastSelectedWithoutShift: any
) => {
  const itemIds = items.map(item => item.instanceId);

  if (shiftKey) {
    const clickedRowIndex = itemIds.indexOf(selectedRowInstanceId);

    let indexOfLastSelected = itemIds.indexOf(lastSelectedWithoutShift);
    if (selectedItems.length === 0 || indexOfLastSelected === -1) {
      indexOfLastSelected = 0;
      setLastSelectedWithoutShift(itemIds[0]);
    }

    let startIndex = Math.min(clickedRowIndex, indexOfLastSelected);
    let endIndex = Math.max(clickedRowIndex, indexOfLastSelected);

    return selectRowRange(items, startIndex, endIndex);
  } else {
    return selectSingleRow(selectedItems, selectedRowInstanceId);
  }
};

export const selectRowRange = (
  items: Item[],
  startIndex: number,
  lastIndex: number
) => {
  let selectedRows: string[] = [];
  for (let index = startIndex; index <= lastIndex; index++) {
    selectedRows.push(items[index].instanceId);
  }
  return selectedRows;
};

export const selectSingleRow = (
  selectedItems: Item[],
  currentInstanceId: any
) => {
  const selectedRows = selectedItems.map(item => item.instanceId);
  selectedRows.push(currentInstanceId);
  return selectedRows;
};
