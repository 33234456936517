import * as React from 'react';
export const SvgShareIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" {...props}>
    <path
      fill="#008BE1"
      d="M27 14.4v-.6C27 9.3 24.4 5 19 5c-4.4 0-6 2.6-7.2 5.5-.8-.6-1.6-1-2.6-1-2.2 0-4 1.8-4 4.1v.6c-3 .4-5.2 3.1-5.2 6.3C0 24.1 2.8 27 6.2 27h19.6c3.4 0 6.2-3.1 6.2-6.5 0-3.3-2.2-5.7-5-6.1m-7.6 10.4c-1.5 0-2.6-1.2-2.6-2.7v-.4L12.4 19c-.4.4-1 .6-1.5.6-1.5 0-2.6-1.2-2.6-2.7 0-1.5 1.2-2.7 2.6-2.7.6 0 1.2.3 1.5.6l4.5-2.8v-.4c0-1.5 1.2-2.7 2.6-2.7 1.4 0 2.5 1.2 2.5 2.7 0 1.5-1.2 2.7-2.6 2.7-.6 0-1.2-.3-1.5-.6l-4.5 2.8v.8l4.5 2.9c.4-.4 1-.6 1.5-.6 1.4 0 2.5 1.2 2.6 2.6 0 1.4-1.1 2.6-2.6 2.6"
    />
  </svg>
);
export default SvgShareIcon;
