import { Item } from '../entities/entities';
import { Action } from './types';
import { SET_SELECTION, INVALIDATE_SELECTION } from './actionConstants';
import { store } from '../store/configureStore';

export const setSelection = (selection: string[]): Action<any> => {
  return {
    type: SET_SELECTION,
    payload: {
      selectedItems: store
        .getState()
        .data.items.filter(
          (item: Item) => selection.indexOf(item.instanceId) !== -1
        ),
      selectedItemsIds: selection,
    },
  };
};

export const invalidateSelection = () => {
  return {
    type: INVALIDATE_SELECTION,
  };
};
